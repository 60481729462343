@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/*==============================
	Common styles
==============================*/
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  background-color: #000747;
  color: #fff;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  transition: 0.5s;
  transition-property: color, background-color, border-color;
  cursor: pointer;
}

button:focus {
  outline: none;
}

a {
  transition: 0.5s;
  transition-property: color, background-color, border-color;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.input-group .form-check {
  margin-right: 30px;
  margin-top: 15px;
}

.input-group .form-check label {
  color: #43adf7;
}

.input-group .form-check-input:checked {
  background-color: #43adf7;
  border-color: #43adf7;
}

.verification-list li {
  position: relative;
  color: #43adf7;
  margin-bottom: 7px;
  padding-left: 20px;
}

.verification-list li::before {
  content: "";
  position: absolute;
  background: url(assets/images/list-arrow.png) no-repeat 50%;
  width: 12px;
  height: 12px;
  left: 0px;
  top: 6px;
}

.verify-sub-title {
  font-size: 18px;
}

.img_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #1736a9;
  border-radius: 10px;
  background: #00063c;
}

.file_box {
  position: relative;
  width: 100%;
}

.security_panel_row .file_box {
  width: auto;
  margin-top: -50px;
}

.full-description {
  max-width: 100% !important;
}

.kycimg {
  height: 100px !important;
  width: unset !important;
}

.file_box label {
  border: 1px solid #1736a9;
  border-radius: 10px;
  background: #00063c;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: #43adf7;
}

.file_box .form-label span {
  color: #3340b6;
}

.file_box input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 0;
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.order_bg {
  position: relative !important;
  z-index: 9 !important;
}

.orderbook_body_wrap_buy .order_bg {
  /* background-color: green;
  opacity: 0.2; */
  background-color: rgb(82 183 117 / 20%);
  height: 100%;
  display: inline-block;
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
}

.orderbook_body_wrap_sell .order_bg {
  height: 100%;
  background-color: rgba(255, 56, 56, 0.1);
  display: inline-block;
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
}

input,
textarea,
select {
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  box-shadow: none;
  transition: 0.5s;
  transition-property: color, border-color;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

select::-ms-expand {
  display: none;
}

.no-underline {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* ::-moz-selection {
  background: #fff;
  color: #151f30;
  text-shadow: none;
}
::selection {
  background: #fff;
  color: #151f30;
  text-shadow: none;
} */
::-webkit-input-placeholder {
  color: #f2f2f2;
  opacity: 1;
}

::-moz-placeholder {
  color: #f2f2f2;
  opacity: 1;
}

:-moz-placeholder {
  color: #f2f2f2;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #f2f2f2;
  opacity: 1;
}

.tab-content {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.fade {
  transition: opacity 0.4s linear;
}

.highcharts-background {
  fill: transparent !important;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

/* Custom Scrollbar */
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px 01052f;
  border-radius: 10px !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #040f56;
  border-radius: 10px !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px 01052f;
}

*::-webkit-scrollbar-thumb {
  background-color: #040f56;
  border-radius: 10px !important;
}

/*==============================
	Header
==============================*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000747;
  z-index: 99;
  /* transition: 0.5s, margin 0s; */
}

.header__content {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  height: 100px;
  position: relative;
}

.header__content nav.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header--active {
  box-shadow: 0 2px 5px #0000004d;
}

.header__logo {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* height: 70px; */
  /* width: 80%; */
}

.header__logo img {
  width: 70px;
  height: auto;
  display: block;
}

.header__btn {
  position: absolute;
  width: 24px;
  height: 22px;
  display: block;
  right: 15px;
  top: 39px;
  box-shadow: none !important;
  border: none;
}

.header__btn span {
  position: absolute;
  right: 0;
  width: 24px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  transition: 0.5s;
  opacity: 1;
}

.header__btn span:first-child {
  top: 0;
}

.header__btn span:nth-child(2) {
  top: 10px;
  width: 16px;
}

.header__btn span:last-child {
  top: 20px;
  width: 8px;
}

.header__btn--active span {
  background-color: #2f80ed;
}

.header__btn--active span:first-child {
  transform: rotate(45deg);
  top: 9px;
}

.header__btn--active span:nth-child(2) {
  opacity: 0;
}

.header__btn--active span:last-child {
  width: 24px;
  transform: rotate(-45deg);
  top: 9px;
}

.desktop_none {
  display: none !important;
}

.home_navbar_middle {
  margin-left: 100px;
}

.home_navbar_middle li + li {
  margin-left: 30px;
}

.home_navbar_middle li a {
  font-size: 18px;
  color: #fff !important;
  transition: unset;
}

.home_navbar_middle li a:hover,
.home_navbar_middle li a.active {
  color: #44affc !important;
}

/*==============================
	Sidebar
==============================*/
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgb(0, 5, 53);
  background: linear-gradient(
    320deg,
    rgba(0, 5, 53, 1) 0%,
    rgba(4, 46, 187, 1) 100%
  );
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 101;
  width: 260px;
  transform: translateX(-100%);
  transition: 0.5s ease;
}

.sidebar__logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding: 0 30px;
  background-color: transparent;
  width: 100%;
  position: relative;
}

.sidebar__logo img {
  width: 70px;
  height: auto;
  display: block;
}

.sidebar__user {
  padding: 20px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: rgb(0 2 20 / 25%);
}

.sidebar__user-img {
  width: 41px;
  height: 41px;
  border-radius: 100px;
  background: #042cb4;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: default;
}

.sidebar__user-img span {
  font-size: 18px;
  font-weight: 700;
}

.sidebar__user-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.sidebar__user-title p {
  color: #fff;
  display: block;
  font-size: 18px;
  margin-bottom: 0;
  line-height: 100%;
}

.sidebar__user-title a {
  display: block;
  color: #43adf7;
  font-size: 14px;
  line-height: 100%;
  margin-top: 5px;
}

.sidebar__user-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 100px;
  background-color: #43adf7;
  margin-left: auto;
}

.sidebar__user-btn svg {
  fill: #01125c;
  width: 14px;
  height: auto;
  transition: fill 0.5s;
}

.sidebar__user-btn:hover {
  background-color: #000747;
}

.sidebar__user-btn:hover svg {
  fill: #43adf7;
}

.sidebar__nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 30px 20px;
  width: 100%;
  max-height: calc(100vh - 280px);
  overflow: auto;
}

.sidebar__nav-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  padding: 10px 15px;
  color: #ffffff;
  text-decoration: none !important;
  border: 1px solid transparent;
  transition: 0.2s all;
  border-radius: 15px;
  background: transparent;
}

.sidebar__nav-link.active,
.sidebar__nav-link:hover {
  border: 1px solid #1736a9;
  background: rgb(54, 97, 254);
  background: linear-gradient(
    330deg,
    rgb(54 97 254 / 35%) 0%,
    rgb(0 9 74 / 71%) 25%,
    rgba(0, 9, 74, 0.6) 50%,
    rgb(0 9 74 / 59%) 75%,
    rgb(54 97 254 / 35%) 100%
  );
}

.sidebar__nav-link svg {
  fill: #e0e0e0;
  width: 20px;
  height: auto;
  transition: fill 0.5s;
  margin-right: 12px;
}

.sidebar__nav-link svg:last-child {
  width: 16px;
  margin-right: 0;
  margin-top: 2px;
  margin-left: 2px;
  margin-bottom: 0;
}

/* .sidebar__nav-link:hover svg {
  fill: #2f80ed;
} */
.sidebar__nav-link--active,
.sidebar__nav-link[aria-expanded="true"] {
  color: #2f80ed;
}

.sidebar__nav-link--active svg,
.sidebar__nav-link[aria-expanded="true"] svg {
  fill: #2f80ed;
}

.sidebar__nav-link--active {
  cursor: default;
}

.sidebar__nav-item {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.sidebar__nav-item:last-child {
  margin-bottom: 0;
}

.sidebar__menu {
  padding: 20px 0;
  display: block;
  width: 100%;
  min-width: 200px;
  text-align: left;
  margin-top: 15px;
  overflow: hidden;
  background-color: rgba(47, 128, 237, 0.1);
}

.sidebar__menu li {
  padding: 0 0 0 49px;
  margin-bottom: 15px;
  position: relative;
}

.sidebar__menu li:before {
  content: "";
  position: absolute;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #2f80ed;
  top: 50%;
  left: 30px;
  margin-top: -2px;
}

.sidebar__menu li:last-child {
  margin-bottom: 0;
}

.sidebar__menu a {
  font-size: 14px;
  color: #e0e0e0;
  display: block;
  font-weight: 400;
}

.sidebar__menu a:hover {
  color: #2f80ed;
}

.sidebar__menu a.active {
  color: #2f80ed;
  cursor: default;
}

.sidebar__copyright {
  margin-top: auto;
  padding: 0 30px 20px;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.sidebar__copyright a {
  color: #fff;
}

.sidebar__copyright a:hover {
  color: #2f80ed;
}

.sidebar--active {
  transform: translateX(0) !important;
}

.home_menu_bar {
  display: flex;
  align-items: center;
}

.home_menu_bar ul li + li {
  margin-left: 30px;
}

.home_menu_bar ul li a {
  color: #fff;
  transition: 0.2s all;
}

.home_menu_bar ul li a.primary_btn {
  color: #44affc;
  font-size: 16px;
  font-weight: 400;
  padding: 7px 32px !important;
}

.home_menu_bar ul li a.primary_btn:hover {
  color: #000747 !important;
  background: #44affc;
}

.home_menu_bar ul li a.active,
.home_menu_bar ul li a:hover {
  color: #44affc !important;
}

.dash_top_header ul.navbar-nav {
  flex-direction: row;
}

.dash_top_header ul.navbar-nav li + li {
  margin-left: 15px;
}

.dash_top_header ul.navbar-nav li a {
  color: #fff;
  font-size: 16px;
  transition: 0.2s all;
}

.dash_top_header ul.navbar-nav li a:hover,
.dash_top_header ul.navbar-nav li a.active {
  color: #44affc;
}

/*==============================
	Main
==============================*/
.main {
  position: relative;
  margin-top: 100px;
  padding: 20px 0 0;
}

.page_header {
  margin-top: 120px;
  position: relative;
  z-index: 1;
  padding: 15px 0 80px;
}

.page_header::before {
  content: "";
  background: url("../src/assets/images/banner_waves.png") no-repeat center;
  width: 100%;
  height: 1163px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.page_header h1 {
  font-weight: 700;
  font-size: 40px;
  max-width: 80%;
  margin: 30px 0;
}

.page_header h1 span {
  color: #e6a123;
}

.page_header h4 span {
  color: #f88204;
}

.page_header h5 {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  max-width: 70%;
}

.banner_content {
  margin-top: -60px;
}

.get_start_btn {
  color: #000748;
  font-size: 20px;
  font-weight: 700;
  background: #81cafc;
  background: linear-gradient(
    90deg,
    rgba(129, 202, 252, 1) 0%,
    rgba(45, 172, 255, 1) 100%
  );
  border-radius: 30px;
  padding: 15px 30px;
  box-shadow: inset 0 -10px 15px 2px rgb(0 0 255 / 80%),
    0 0 12px 2px rgb(129 202 252 / 50%), 0 10px 10px 3px rgb(24 35 137 / 80%);
  transition: 0.2s all;
}

.get_start_btn a {
  color: #000748;
  text-decoration: none;
}

.get_start_btn:hover {
  background: linear-gradient(
    90deg,
    rgba(45, 172, 255, 1) 0%,
    rgba(129, 202, 252, 1) 100%
  );
  box-shadow: inset -10px 0 15px 2px rgb(0 0 255 / 80%),
    0 0 12px 2px rgb(129 202 252 / 50%), 10px 0 10px 3px rgb(24 35 137 / 80%);
}

.get_start_btn:hover a {
  color: #000748;
}

.banner_content .get_start_btn {
  margin-top: 50px;
}

.section {
  padding: 50px 0;
}

section.market_section {
  z-index: 1;
  position: relative;
}

.market_section_panel {
  border: 1px solid #1736a9;
  background: rgb(54, 97, 254);
  background: linear-gradient(
    326deg,
    rgb(54 97 254 / 50%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgba(54, 97, 254, 0.5) 100%
  );
  border-radius: 50px;
  padding: 40px 60px;
}

.market_table_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.market_table_head h2 {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.market_table thead th {
  color: #6e8bf5;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  border-bottom: 1px solid #0e1874;
  vertical-align: middle;
  padding: 15px;
  white-space: nowrap;
}

.market_table tbody td {
  font-size: 18px;
  font-weight: 700;
  background: transparent;
  color: #fff;
  border-bottom: 1px solid #0e1874;
  vertical-align: middle;
  padding: 15px;
  white-space: nowrap;
}

.market_table_icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.market_table h5 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.market_table h5 span {
  color: #b1b5c3;
  margin-left: 20px;
}

.green_txt {
  color: #58bd7d !important;
}

.red_txt {
  color: #ff3838 !important;
}

.blue_txt {
  color: #3996da !important;
}

.trade_btn {
  background: #58bd7d;
  border: 1px solid #58bd7d;
  border-radius: 30px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding: 3px 22px;
  transition: 0.2s all;
}

.trade_btn a {
  color: #fff;
  text-decoration: none;
}

.trade_btn:hover {
  background: transparent;
  border: 1px solid #58bd7d;
  color: #58bd7d;
}

.trade_btn:hover a {
  color: #58bd7d;
}

.primary_btn {
  color: #45b1fc;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid #45b1fc;
  background: transparent;
  transition: 0.2s all;
  padding: 10px 40px;
  border-radius: 15px;
}

.primary_btn:hover {
  color: #000747;
  background: #45b1fc;
}

.primary_btn a {
  color: #45b1fc;
  text-decoration: none;
}

.primary_btn:hover a {
  color: #000747;
}

.market_table_btn_grps button {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #81cafc;
  font-size: 15px;
  font-weight: 500;
  transition: 0.2s all;
  padding: 7px 20px;
}

.market_table_btn_grps button + button {
  margin-left: 8px;
}

.market_table_btn_grps button.active,
.market_table_btn_grps button:hover {
  background: #81cafc;
  border: 1px solid #81cafc;
  color: #000747;
}

.features_section h2 {
  color: #e6a123;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
}

.features_section h2 span {
  color: #ffffff;
  font-weight: 400;
}

.features_section h5 {
  font-size: 18px;
  font-weight: 500;
}

.features_section h5 span {
  color: #e6a123;
}

.features_panel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 50px;
}

.features_div {
  background: #000747;
  background: linear-gradient(
    315deg,
    rgb(0 7 71) 0%,
    rgb(0 7 71) 40%,
    rgba(54, 97, 254, 1) 100%
  );
  border: 1px solid #000747;
  transition: 0.2s all;
  width: 300px;
  border-radius: 50px;
  text-align: center;
  padding: 30px;
  position: relative;
  overflow: hidden;
  z-index: 10;
  transition: 0.2s all;
  cursor: default;
}

/* .features_div::before {
  content: "";
  background: url("../src/assets/images/feature_box_shadow.png") no-repeat;
  width: 382px;
  height: 371px;
  position: absolute;
  left: -80px;
  top: 0;
  z-index: -1;
  mix-blend-mode: soft-light;
} */
.features_div:hover {
  border: 1px solid #1736a9;
  background: rgb(54, 97, 254);
  background: linear-gradient(
    -38deg,
    rgb(54 97 254 / 40%) 0%,
    rgb(0 9 74 / 80%) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgb(0 9 74 / 80%) 75%,
    rgb(54 97 254 / 60%) 100%
  );
}

.features_div h3 {
  font-size: 30px;
  font-weight: 700;
  margin: 20px 0 15px;
}

.features_div p {
  font-size: 18px;
  min-height: 80px;
}

.features_div a {
  font-size: 18px;
  color: #45b1fc;
}

.ctaone_box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #1736a9;
  border-radius: 50px;
  padding: 80px 30px;
  background: rgb(54, 97, 254);
  background: linear-gradient(
    330deg,
    rgb(54 97 254 / 35%) 0%,
    rgb(0 9 74 / 71%) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgb(0 9 74 / 59%) 75%,
    rgb(54 97 254 / 35%) 100%
  );
}

.ctaone_box div {
  text-align: center;
}

.ctaone_box div h2 {
  color: #e6a123;
  font-size: 48px;
  font-weight: 700;
}

.ctaone_box div h5 {
  color: #64c0fd;
  font-size: 18px;
  margin-bottom: 0;
}

.ctatwo_section {
  margin-bottom: -200px;
}

.ctatwo_box {
  position: relative;
  z-index: 10;
  background: #000087;
  border: 1px solid #1736a9;
  border-radius: 50px;
  padding: 50px;
  overflow: hidden;
}

.ctatwo_box::before {
  content: "";
  background: url("../src/assets/images/cta_bg.png") no-repeat left center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  mix-blend-mode: luminosity;
}

.ctatwo_box h2 {
  color: #e6a123;
  font-weight: 700;
  font-size: 40px;
}

.ctatwo_box h2 span {
  color: #fff;
  font-weight: 400;
}

.ctatwo_box p {
  font-size: 20px;
  color: #fff;
}

.ctatwo_box p span {
  color: #e6a123;
}

.ctatwo_box > div {
  max-width: 35%;
  margin-left: auto;
}

.mobile_app_section h2 {
  font-size: 48px;
  font-weight: 700;
}

.mobile_app_section {
  background: #010639;
  padding-top: 220px;
}

.mobile_app_section p {
  font-size: 20px;
}

.mobile_app_section ul li {
  margin: 30px 0;
  position: relative;
  padding: 15px 0;
}

.mobile_app_section ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.mobile_app_section ul li a div {
  margin-left: 20px;
}

.mobile_app_section ul li a h5 {
  color: #64c0fd;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 3px;
}

.mobile_app_section ul li a h2 {
  color: #fff;
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 0;
}

.mobile_app_section ul li::before {
  content: "";
  background: #1736a9;
  height: 1px;
  width: 50%;
  position: absolute;
  bottom: -18px;
}

footer {
  background: #01042a;
  padding: 50px 0;
  z-index: 10;
  position: relative;
}

.footer_logo {
  width: 90px;
  margin-left: 3.5rem;
}

ul.quick_link {
  display: flex;
  flex-wrap: wrap;
}

ul.quick_link li {
  width: 50%;
}

ul.quick_link li + li {
  margin-bottom: 15px;
}

ul.quick_link li a {
  font-size: 18px;
  color: #b8d8e8;
  text-decoration: none;
  transition: 0.2s all;
}

ul.quick_link li a:hover {
  color: #45b1fc;
}

ul.social_links {
  display: flex;
}

ul.social_links li + li {
  margin-left: 30px;
}

ul.social_links li a {
  font-size: 24px;
  transition: 0.2s all;
  color: #b8d8e8;
}

ul.social_links li a:hover {
  color: #152c7d;
}

.footer_top {
  padding-bottom: 30px;
}

.footer_top h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.footer_top p {
  color: #b8d8e8;
  font-size: 18px;
}

.footer_bottom {
  border-top: 1px solid #1736a9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}

.footer_bottom p {
  font-size: 15px;
  margin-bottom: 0;
}

.footer_bottom ul {
  display: flex;
}

.footer_bottom ul li a {
  font-size: 15px;
  color: #fff;
  text-decoration: none;
  transition: 0.2s all;
}

.footer_bottom ul li a:hover {
  color: #45b1fc;
}

.footer_bottom ul li {
  position: relative;
}

.footer_bottom ul li + li {
  margin-left: 30px;
}

.footer_bottom ul li::after {
  content: "";
  background: #fff;
  width: 1px;
  height: 13px;
  position: absolute;
  top: 5px;
  right: -15px;
}

.footer_bottom ul li:last-child:after {
  display: none;
}

.subscribe_form {
  position: relative;
}

.subscribe_form input {
  border: 1px solid #1736a9 !important;
  background: transparent !important;
  border-radius: 10px;
  font-size: 14px;
  padding: 16px 12px;
  box-shadow: none !important;
  color: #fff !important;
  margin-top: 30px;
}

.subscribe_form input::placeholder {
  color: #3e6969;
}

.subscribe_form button {
  position: absolute;
  top: 9px;
  right: 8px;
  background: #81cafc;
  border-radius: 10px;
  color: #01042a;
  font-size: 16px;
  font-weight: 700;
  padding: 6px 25px;
}

.why_choose_section h2 {
  font-size: 24px;
  font-weight: 700;
}

.why_choose_section h2 span {
  color: #e6a123;
  font-size: 56px;
  line-height: 60px;
  display: block;
}

.why_choose_section_img {
  background-repeat: no-repeat;
  background-position: center center;
  /* background: url("https://credexchange-frontend.pages.dev/assets/images/why_choose_img_dark.png") no-repeat center; */
  background-size: 100% !important;
  width: 100%;
  height: 801px;
}

.why_choose_div {
  border: 1px solid #1736a9;
  background: rgb(54, 97, 254);
  background: linear-gradient(
    326deg,
    rgb(54 97 254 / 50%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgba(54, 97, 254, 0.5) 100%
  );
  border-radius: 50px;
  padding: 30px;
  margin-bottom: 30px;
  min-height: 380px;
}

.why_choose_div h3 {
  font-size: 20px;
  font-weight: 700;
  color: #e6a123;
  margin: 15px 0 10px;
}

/*==============================
	Sign
==============================*/
.login_wrapper {
  display: flex;
}

.authbanner_wrapper {
  display: flex;
  flex-direction: column;
  width: 600px;
  min-height: 100vh;
  background: rgb(0, 5, 53);
  background: linear-gradient(
    315deg,
    rgba(0, 5, 53, 1) 40%,
    rgba(4, 46, 187, 1) 100%
  );
  overflow: hidden;
  position: relative;
  padding: 50px;
  z-index: 1;
}

.authbanner_wrapper::before {
  content: "";
  background: url("../src/assets/images/login_waves_bg.png") no-repeat right
    bottom;
  width: 100%;
  height: 1000px;
  position: absolute;
  bottom: -160px;
  right: 10px;
  z-index: -1;
}

.auth_logo {
  width: 150px;
  margin-left: 8rem;
}

.auth_right_img {
  position: absolute;
  bottom: -30px;
}

.auth_content {
  margin-top: 30px;
  padding: 0 40px;
}

.auth_content h2 {
  color: #ffe600;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 30px;
}

.auth_content p {
  font-size: 16px;
}

.auth_form_wrapper {
  width: calc(100% - 600px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign_content {
  width: 450px;
}

.sign_content > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.sign_content > div h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}

.signin_btns {
  display: flex;
}

.signin_btns button {
  background: #1644a3;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #000747;
  font-size: 20px;
  transition: 0.2s all;
}

.signin_btns button:hover,
.signin_btns button.active {
  background: #43adf7;
}

.signin_btns button + button {
  margin-left: 15px;
}

.sign_group_input {
  border: 1px solid #1736a9;
  border-radius: 10px;
  background: #00063c;
  padding: 12px 20px;
}

.sign_group_input label {
  color: #3340b6;
  font-size: 16px;
}

.sign_input {
  background-color: transparent;
  border: 1px solid transparent;
  position: relative;
  color: #43adf7;
  font-size: 16px;
  width: 100%;
  display: block;
  padding: 5px 0 0 5px;
}

.sign_input::placeholder {
  color: #43adf7;
}

.sign_textarea {
  background-color: #151f30;
  border: 1px solid transparent;
  border-radius: 16px;
  height: 144px;
  position: relative;
  color: #fff;
  font-size: 14px;
  width: 100%;
  padding: 15px 20px;
  resize: none;
}

.sign_textarea:focus {
  background-color: #151f30;
  border-color: #2f80ed;
}

.sign_group {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}

.signup_form .sign_group {
  margin-bottom: 0px !important;
}

.sign_group_checkbox {
  width: 100%;
  text-align: left;
}

.sign_group_checkbox input:not(:checked),
.sign_group_checkbox input:checked {
  position: absolute;
  left: -9999px;
}

.sign_group_checkbox input:not(:checked) + label,
.sign_group_checkbox input:checked + label {
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  line-height: 22px;
  margin: 0;
}

.sign_group_checkbox input:not(:checked) + label a,
.sign_group_checkbox input:checked + label a {
  color: #2f80ed;
}

.sign_group_checkbox input:not(:checked) + label a:hover,
.sign_group_checkbox input:checked + label a:hover {
  color: #2f80ed;
  text-decoration: underline;
}

.sign_group_checkbox input:not(:checked) + label:before,
.sign_group_checkbox input:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  background-color: #00063c;
  border: 1px solid #1736a9;
  border-radius: 5px;
}

.sign_group_checkbox input:not(:checked) + label:after,
.sign_group_checkbox input:checked + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  text-align: center;
  transition: 0.5s;
  background: url("./assets/images/checkmark.svg") no-repeat center/12px auto;
  border-radius: 8px;
}

.sign_group_checkbox input:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.sign_group_checkbox input:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.sign_group_checkbox label::-moz-selection {
  background: transparent;
  color: #e0e0e0;
}

.sign_group_checkbox label::selection {
  background: transparent;
  color: #e0e0e0;
}

.sign_btn {
  margin: 15px auto 0;
  width: 100%;
  border-radius: 15px;
  background-color: #45b1fc;
  border: 1px solid #45b1fc;
  font-size: 20px;
  color: #000747;
  text-transform: capitalize;
  font-weight: 700;
  padding: 8px;
}

.sign_btn:hover {
  color: #45b1fc;
  background-color: transparent;
}

.sign_text {
  margin-top: 20px;
  font-size: 18px;
  color: #fff;
  display: block;
  text-align: center;
}

.sign_text a {
  position: relative;
  color: #43adf7;
  text-decoration: none;
}

.sign_text a:hover {
  color: #43adf7;
  text-decoration: underline;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Dashboard */
.dashbox {
  border: 1px solid #1736a9;
  background: rgb(54, 97, 254);
  background: linear-gradient(
    326deg,
    rgb(54 97 254 / 50%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgba(54, 97, 254, 0.5) 100%
  );
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
}

.dashbox_title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.dashbox_title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.dashbox_title_flex .dashbox_title {
  margin-bottom: 0;
}

.dashbox_title_flex select {
  box-shadow: none !important;
  border: 1px solid #1736a9 !important;
  background: #00063c url("../src/assets/images/select_dropdown.png") no-repeat
    right 0.75rem center;
  background-size: 16px 9px;
  font-size: 14px;
  color: #fff;
  width: 100px;
  border-radius: 10px;
}

.dash_chart_panel {
  display: flex;
  gap: 30px;
}

.dash_chart_detail_top h2 {
  font-size: 24px;
  font-weight: 500;
}

.dash_chart_detail_top h5 {
  color: #43adf7;
  font-size: 16px;
}

.dash_chart_detail {
  width: 80%;
}

.dash_chart_detail_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #0e1874;
  padding-bottom: 15px;
}

.dash_chart_detail_bottom {
  padding-top: 15px;
}

ul.chart_list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.chart_list li {
  position: relative;
  padding-left: 20px;
  width: 46%;
}

.chart_list li::before {
  content: "";
  background: #f3ba2f;
  width: 15px;
  height: 15px;
  border-radius: 5px;
  position: absolute;
  top: 5px;
  left: 0;
}

.chart_list li:nth-child(2):before {
  background: #3ebeff;
}

.chart_list li:nth-child(3):before {
  background: #2d75ca;
}

.chart_list li:nth-child(4):before {
  background: #26a17b;
}

.chart_list li label {
  font-size: 18px;
  font-weight: 500;
}

.chart_list li label:nth-child(2) {
  color: #43adf7;
  width: 70%;
}

.chart_list li label:nth-child(1) {
  width: 30%;
}

.deposit_btn,
.withdraw_btn {
  font-size: 15px;
  font-weight: 700;
  border-radius: 15px;
  text-decoration: none;
  padding: 10px;
  min-width: 150px;
  display: inline-block;
  text-align: center;
  transition: 0.2s all;
}

.fiat_wallet_btns .deposit_btn,
.fiat_wallet_btns .withdraw_btn {
  min-width: 100px;
  padding: 8px 10px;
}

.fiat_wallet_btns a + a {
  margin-left: 10px;
}

.fiat_wallet_btns {
  margin-top: 15px;
}

.deposit_btn {
  color: #000747;
  background: #8afe67;
  background: linear-gradient(
    138deg,
    rgba(138, 254, 103, 1) 0%,
    #50b432 50%,
    rgba(138, 254, 103, 1) 100%
  );
  border: 1px solid #4ece28;
}

.withdraw_btn {
  color: #000747;
  background: #43adf7;
  border: 1px solid #43adf7;
}

.withdraw_btn a {
  color: #000747;
  text-decoration: none;
}

.deposit_btn:hover {
  /* background: linear-gradient(-138deg, rgba(138,254,103,1) 0%, #50b432 50%, rgba(138,254,103,1) 100%); */
  background: transparent;
  color: #8afe67;
}

.withdraw_btn:hover {
  background: transparent;
  color: #43adf7;
}

.withdraw_btn:hover a {
  color: #43adf7;
}

.dash_chart_detail_top div:nth-child(2) a + a {
  margin-left: 20px;
}

.verify_box {
  min-height: 180px;
}

.dashbox.verify_box {
  padding: 30px 20px;
}

.verify_box h2 {
  font-size: 20px;
  font-weight: 500;
}

.verify_box h5 {
  font-size: 16px;
  font-weight: 400;
  color: #43adf7;
  margin-bottom: 0;
}

.verify_box svg {
  height: 50px;
  fill: #43adf7;
  margin-bottom: 15px;
}

.primary_table thead th {
  color: #43adf7;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
  border-bottom: 1px solid #0e1874;
  vertical-align: middle;
  padding: 10px 50px 10px 0;
  white-space: nowrap;
  cursor: default;
}

.primary_table tbody td {
  font-size: 16px;
  background: transparent;
  color: #fff;
  border-bottom: 1px solid #0e1874;
  vertical-align: middle;
  padding: 10px 50px 10px 0;
  white-space: nowrap;
  cursor: default;
}

.airdrop_panel {
  background: rgb(4, 49, 90);
  background: linear-gradient(
    138deg,
    rgba(4, 49, 90, 1) 0%,
    rgba(4, 14, 26, 1) 100%
  );
  border-radius: 15px;
  position: relative;
  text-align: right;
  z-index: 10;
  padding: 20px;
  min-height: 180px;
  cursor: default;
  overflow: hidden;
  margin-bottom: 30px;
}

.airdrop_panel::before {
  content: "";
  /* background: url("../src/assets/images/cms-airdrop_bg.png") no-repeat; */
  width: 620px;
  height: 210px;
  position: absolute;
  left: -180px;
  bottom: 0;
  z-index: -1;
}

.airdrop_panel h2 {
  font-size: 20px;
  line-height: 16px;
  font-weight: 400;
  max-width: 40%;
  margin-left: auto;
  margin-bottom: 5px;
}

.airdrop_panel h1 {
  font-size: 26px;
  font-weight: 600;
  color: #f4de65;
  max-width: 77%;
  margin-left: auto;
  padding-bottom: 8px;
  position: relative;
}

.airdrop_panel h1::after {
  content: "";
  background: #fff;
  width: 60%;
  height: 1px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.airdrop_panel h3 {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

.last_trans_list_row {
  border-bottom: 1px solid #0e1874;
  padding: 10px 0;
  cursor: default;
}

.last_trans_list_row:last-child {
  border-bottom: none;
}

.last_trans_list_row > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
}

.last_trans_list_row h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.last_trans_list_row h5 {
  font-size: 14px;
  font-weight: 400;
  color: #43adf7;
  margin: 0;
}

.last_trans_list_row h3 {
  font-size: 16px;
  font-weight: 400;
}

.flex_height [class*="col-"] {
  margin-bottom: 30px;
}

.security_panel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #0e1874;
  padding: 20px 0;
}

.security_panel_row:last-child {
  border-bottom: none !important;
  padding-bottom: 0;
}

.security_panel_row svg {
  height: 52px;
  min-width: 52px;
  fill: #43adf7;
  margin-right: 20px;
}

.security_panel_row > div {
  display: flex;
  align-items: flex-start;
}

.security_panel_content h2 {
  font-size: 20px;
  font-weight: 500;
}

.security_panel_content p {
  font-size: 16px;
  color: #43adf7;
  max-width: 70%;
  margin-bottom: 0;
}

.bank_card {
  position: relative;
  background: #16679e url("../src/assets/images/bank_card.png") no-repeat;
  background-size: cover;
  min-height: 232px;
  border-radius: 15px;
  border: 1px solid #1d5ebc;
  padding: 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

.bank_card h2 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
}

.bank_card_middle h3 {
  font-size: 20px;
  font-weight: 400;
  color: #43adf7;
  margin-bottom: 5px;
}

.bank_card_bottom,
.bank_card_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bank_card_bottom h5 {
  font-size: 15px;
  font-weight: 700;
  color: #43adf7;
}

.bank_card_bottom h4 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
}

.bank_card_middle {
  text-align: center;
  padding: 30px 0;
}

.bank_card_top > div {
  display: flex;
  align-items: center;
}

.bank_card_top > div button + button {
  margin-left: 10px;
}

.icon_btn {
  width: 32px;
  height: 32px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #43adf7;
  color: #000747;
}

select.sign_input option {
  background: #00063c;
}

select.sign_input {
  background: #00063c url("../src/assets/images/select_dropdown.png") no-repeat
    right 0.75rem center;
  background-size: 16px 9px;
}

.history_table_icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.primary_inp,
.primary_select {
  background: #01042a;
  border: 1px solid #1736a9;
  color: #3e6969;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
}

.primary_select {
  background: #01042a url("../src/assets/images/select_dropdown.png") no-repeat
    right 0.75rem center;
  background-size: 16px 9px;
  padding-right: 35px;
}

.primary_inp::placeholder {
  color: #3e6969;
}

.primary_inp_grp {
  position: relative;
}

.primary_inp_grp input {
  padding-right: 35px;
}

.primary_inp_grp img {
  position: absolute;
  right: 15px;
  top: 15px;
}

.history_search [class*="col-"] {
  margin-bottom: 15px;
}

.primary_tab {
  margin-bottom: 30px;
}

.primary_tab li button {
  color: #81cafc;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px !important;
  padding: 5px 15px;
  border: 1px solid transparent;
}

.primary_tab li + li {
  margin-left: 10px;
}

.primary_tab li button.active,
.primary_tab li button:hover {
  background-color: #81cafc !important;
  border: 1px solid #81cafc;
  color: #000747 !important;
}

.primary_pagination ul.pagination {
  justify-content: flex-end;
  margin-top: 10px;
}

.primary_pagination ul.pagination .page-link {
  background: #01042a;
  border-color: #1736a9;
  color: #fff;
  box-shadow: none;
}

.primary_pagination ul.pagination .page-item.active > .page-link {
  background: #81cafc;
  color: #000747;
}

.primary_pagination ul.pagination .page-link.active {
  background: #81cafc;
  color: #000747;
}

.primary_pagination ul.pagination .page-item.disabled > .page-link {
  color: #3e6969;
}

textarea.sign_input {
  resize: none;
}

.create_ticket_div {
  margin-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #0e1874;
}

.create_ticket_div h5,
.support_ticket_details h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.support_ticket_details {
  padding-top: 30px;
}

.support_ticket_accordian .accordion-item {
  background: transparent;
  border: none;
  border-bottom: 1px solid #0e1874;
  border-radius: 0;
}

.support_ticket_accordian .accordion-button {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 30px;
}

.support_ticket_accordian .accordion-button:not(.collapsed) {
  border-bottom: 1px solid #0e1874;
}

.support_ticket_accordian .accordion-button::after {
  background-image: url("../src/assets/images/plus_icon.png");
  background-size: 100%;
  width: 14px;
  height: 15px;
  position: absolute;
  right: 0;
}

.support_ticket_accordian .accordion-button:not(.collapsed)::after {
  background-image: url("../src/assets/images/minus_icon.png");
}

.support_ticket_accordian .accordion-button h2 {
  font-size: 18px;
  font-weight: 400;
  color: #3996da;
  margin: 0;
  max-width: 65%;
}

.support_ticket_accordian .accordion-button h2 span {
  color: #fff;
}

.support_ticket_accordian span.ticket_id,
.support_ticket_accordian span.ticket_status {
  color: #fff;
  font-size: 16px;
}

.support_ticket_accordian span.ticket_status {
  margin-left: 30px;
}

.support_ticket_body {
  padding: 20px 0;
}

.support_ticket_msg {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.support_ticket_msg img {
  margin-right: 15px;
}

.support_ticket_msg p {
  color: #fff;
}

.support_ticket_msg {
  width: 75%;
  padding: 15px;
  border-radius: 15px;
}

.support_ticket_msg.support_ticket_usermsg {
  background: rgb(0 6 60 / 100%);
}

.support_ticket_msg.support_ticket_adminmsg {
  background: rgb(17 36 129);
  margin-left: auto;
}

.support_btn_grp button {
  padding: 10px 20px;
}

.support_btn_grp button + button {
  margin-left: 15px;
}

.custom-file-button input[type="file"] {
  margin-left: 0 !important;
}

.custom-file-button input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type="file"]::file-selector-button {
  display: none;
}

.custom-file-button label {
  background: #1736a9;
  border-color: #1736a9;
  color: #fff;
  cursor: pointer;
}

.custom-file-button:hover label {
  background: #1736a9;
  border-color: #1736a9;
  color: #fff;
}

.input-group.custom-file-button {
  flex-wrap: nowrap;
}

.support_btn_grp {
  margin-top: 15px;
}

.edit_profile_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #0e1874;
  padding-bottom: 15px;
  margin-bottom: 30px;
}

.edit_profile_head .sign_btn {
  width: unset;
  margin: unset;
  padding: 7px 30px;
}

.edit_profile_head h3 {
  font-size: 18px;
  font-weight: 500;
}

.edit_profile_head p {
  font-size: 14px;
  margin: 0;
}

.edit_profile_user_info {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #0e1874;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.edit_profile_user_info .sidebar__user-img span {
  font-size: 46px;
}

.edit_profile_user_info .sidebar__user-img {
  width: 100px;
  height: 100px;
}

.edit_profile_user_info .sidebar__user-title p {
  font-size: 20px;
  font-weight: 700;
}

.edit_profile_user_info .sidebar__user-title span {
  font-size: 14px;
}

main.inner_cms_layout {
  margin-top: 120px;
  min-height: 80vh;
  padding-bottom: 30px;
}

.notify_count {
  width: 25px;
  height: 25px;
  color: #000535;
  background: #e6a123;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

.notification_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #0e1874;
  padding: 30px 0;
}

.notification_row > div {
  display: flex;
  align-items: flex-start;
  width: 65%;
}

.notification_unread img {
  height: 20px;
  max-width: 20px;
}

.announcements_unread img {
  height: 25px;
  max-width: 25px;
}

.notification_row_content h2 {
  color: #e6a123;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}

.notification_row_content p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

p.notify_date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: #81cafc;
}

.notification_unread {
  position: relative;
}

.notification_unread_dot {
  position: absolute;
  font-size: 7px;
  color: #e6a123;
  top: 2px;
  right: -3px;
}

.notification_row_content {
  margin-left: 20px;
}

.dashbox_title_flex a + a {
  margin-left: 20px;
}

.wallet_balance_overview {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.wallet_balance_overview > div {
  position: relative;
  padding: 30px 0;
}

.wallet_balance_overview > div::before {
  content: "";
  background: #1736a9;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 150%;
}

.wallet_balance_overview > div:last-child::before {
  display: none;
}

.wallet_balance_overview label {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.wallet_balance_overview h3 {
  color: #43adf7;
  font-size: 24px;
  font-weight: 500;
}

.wallet_asset_links a {
  text-decoration: none;
}

.wallet_asset_links a + a {
  margin-left: 30px;
}

.qr_icon {
  right: 50px !important;
  top: 5px !important;
  cursor: pointer;
}

.copy_icon {
  top: 5px !important;
  right: 15px !important;
  cursor: pointer;
}

.choose_crypto_select {
  height: 9px;
}

.deposit_crypto_inp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  cursor: pointer;
}

.deposit_crypto_inp > div {
  display: flex;
  align-items: center;
}

.deposit_crypto_inp > div img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.deposit_crypto_inp > div h4 {
  font-size: 16px;
  font-weight: 400;
  color: #43adf7;
  margin: 0;
}

.deposit_crypto_inp > div h4 span {
  font-size: 13px;
  margin-left: 15px;
}

.primary_modal .modal-content {
  background: #000747;
  border: 1px solid #1736a9;
}

.primary_modal .modal-header {
  border-bottom: 1px solid #1736a9;
}

.primary_modal .btn-close {
  filter: invert(1);
}
.primary_modal .btn-close:focus {
  box-shadow: none;
}
.buycrypto_modal .modal-content {
  background: #141416;
  border: 1px solid #272727;
}

.buycrypto_modal .modal-header {
  border-bottom: 1px solid #272727;
}

.buycrypto_modal .btn-close {
  filter: invert(1);
}

.token_search_list {
  display: flex;
  flex-direction: column;
  height: 400px;
  margin-top: 15px;
  overflow: auto;
  transition: 0.2s linear;
  width: 100%;
}

.token_search_list_row {
  align-items: center;
  display: flex;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  justify-content: space-between;
  padding: 12px 10px;
  width: 98%;
}

.token_search_list_row > div {
  align-items: center;
  display: flex;
}

.token_search_list_row img {
  margin-right: 15px;
  width: 40px;
}

.token_search_list_cur_name {
  display: block;
  font-weight: 700;
}

.token_search_list_cur_value {
  color: #ababab;
}

.token_search_list_row:hover,
.token_search_list_row.active {
  background-color: rgb(68 175 252 / 6%);
}

.qr_code_img img {
  width: 200px;
  margin: 15px 0 20px;
}

.deposit_info {
  display: flex;
  align-items: flex-start;
}

.deposit_info svg {
  fill: #1736a9;
  min-width: 17px;
  height: 17px;
  margin-right: 12px;
  margin-top: 3px;
}

hr.primary_hr {
  border-color: #1736a9;
}

.deposit_bal_div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deposit_bal_div > div + div {
  padding-top: 12px;
}

.deposit_bal_div > div label,
.withdraw_bal_div > div label {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  min-width: 42%;
}

.deposit_bal_div > div h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #e6a123;
  word-break: break-all;
  text-align: right;
}

.deposit_bal_div > div h4,
.withdraw_bal_div > div h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.withdraw_bal_div div + div {
  padding-top: 30px;
}

.withdraw_bal_div > div h4 {
  color: #e6a123;
}

.withdraw_bal_div {
  margin-left: 50px;
}

.withdraw_bal_div > div label {
  margin-bottom: 8px;
}

.input_info {
  position: absolute;
  top: 5px;
  right: 0;
  color: #43adf7;
}

.form_note {
  margin-top: -25px;
  margin-bottom: 25px;
}

.form_note label {
  color: #2a49bb;
  font-size: 14px;
}

.form_note label span {
  color: #43adf7;
}

.fiat_account_details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.fiat_account_details > div span {
  font-size: 16px;
  font-weight: 500;
}

.fiat_account_details > div div span {
  color: #e6a123;
}

.fiat_account_details > div div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 48%;
}

.fiat_account_details > div div img {
  height: 18px;
  cursor: pointer;
}

.fiat_account_details h3 {
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #1736a9;
  padding-bottom: 12px;
  margin-bottom: 20px;
}

.fiat_wallet_notes h3 {
  color: #e6a123;
  font-size: 16px;
  font-weight: 400;
}

.fiat_wallet_notes ul li {
  font-size: 16px;
  margin-bottom: 15px;
  position: relative;
  padding-left: 15px;
}

.fiat_wallet_notes li::before {
  content: "*";
  font-size: 16px;
  position: absolute;
  top: 3px;
  left: 0;
}

.custom_file_upload input.sign_input[type="file"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.custom_file_upload label {
  font-size: 16px;
  color: #43adf7;
  padding: 5px 0 0 0;
}

/* Launchpad Css Starts */
.launchpad_layout {
  background: #000747;
}

.launchpad_page_header {
  position: relative;
  z-index: 1;
}

.launchpad_page_header::before {
  content: "";
  background: url("../src/assets/images/launchpad_bg.png") no-repeat center;
  width: 100%;
  height: 877px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.launchpad_page_header_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: 75vh;
  margin-top: -35px;
  text-align: center;
}

.launchpad_page_header_content h2 {
  font-size: 60px;
  font-weight: 700;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000747;
  text-shadow: -3px 5px 2px #000747;
  margin-bottom: -32px;
}

.launchpad_page_header_content h1 {
  font-size: 140px;
  font-weight: 700;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000747;
  text-shadow: -3px 5px 2px #000747;
}

.launchpad_page_header_content h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.launchpad_list_section {
  z-index: 10;
  position: relative;
}

.launchpad_list_section .primary_tab {
  justify-content: center;
  margin-bottom: 50px;
}

.launchpad_list_section .primary_tab li button {
  font-size: 24px;
  font-weight: 700;
  padding: 8px 25px;
}

.launchpad_box {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #0e1874;
  padding-bottom: 10px;
}

.launchpad_box img {
  display: flex;
  align-items: flex-start;
  width: 125px;
  height: 125px;
  margin-right: 20px;
  border-radius: 100px;
}

.launchpad_box_content h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}

.launchpad_box_content p {
  margin-top: 15px;
}

.launchpad_box_content > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.launchpad_box_content > div span:nth-child(2) {
  width: 60%;
}

.launpad_link {
  padding-top: 30px;
}

.launpad_link a {
  color: #81cafc;
  text-decoration: none;
}

.launchpad_detailbox_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.launchpad_detailbox_content .launchpad_box_content p {
  max-width: 85%;
}

.launchpad_box_content {
  width: 100%;
}

.live_btn {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background: #3661fe;
  border-radius: 10px;
  padding: 5px 2px;
  min-width: 90px;
  text-align: center;
  cursor: default;
}

.coin_web_info svg {
  fill: #81cafc;
  height: 24px;
  margin-right: 10px;
}

.launchpad_box_content .coin_web_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  gap: 50px;
}

.coin_web_info a {
  text-decoration: none;
  /* width: 28%; */
  color: #81cafc;
  display: flex !important;
  align-items: center;
}

.token_supply_details {
  margin-left: 145px;
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
}

.token_supply_details_left {
  width: 50%;
  border-right: 1px solid #0e1874;
}

.token_supply_details_left > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.token_supply_details_left > div span:nth-child(2) {
  width: 50%;
}

.token_supply_launchpad {
  width: 50%;
  text-align: center;
  padding: 10%;
}

.token_supply_details_right {
  width: 50%;
  text-align: center;
}

.token_supply_details_right h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.countdown_wrapper .countdown_times {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px;
}

.countdown_wrapper .countdown_times p:nth-child(1) {
  font-size: 30px;
  font-weight: 700;
  background: rgb(23, 30, 174);
  background: linear-gradient(
    -73deg,
    rgba(23, 30, 174, 1) 0%,
    rgba(10, 0, 65, 1) 100%
  );
  border: 2px solid #1736a9;
  width: 74px;
  height: 74px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.countdown_wrapper .countdown_times p:nth-child(2) {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 16px;
}

.countdown_wrapper .countdown_times span {
  font-size: 38px;
  font-weight: 700;
  color: #2d52de;
  margin-top: -28px;
}

.countdown_wrapper {
  width: 70%;
  margin: 0 auto;
}

.token_eventbox {
  text-align: center;
}

.token_eventbox h2 {
  font-size: 16px;
  font-weight: 400;
  color: #81cafc;
  margin-top: 12px;
}

.token_eventbox h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 4px 0 0;
}

.launchpad_terms {
  padding-left: 15px;
}

.launchpad_terms li {
  margin-bottom: 20px;
}

/* CMS pages css */
.about_us_header {
  position: relative;
  z-index: 1;
  padding-bottom: 130px;
}

.about_us_header::before {
  content: "";
  background: url("../src/assets/images/about_bg.png") no-repeat center;
  width: 100%;
  height: 866px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.about_us_page_header_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
  text-align: center;
}

.about_us_page_header_content h1 {
  font-size: 50px;
  font-weight: 700;
  max-width: 60%;
  margin: 0 auto;
}

.about_us_page_header_content h1 span {
  color: #e6a123;
}

.about_us_page_header_content h5 {
  font-size: 18px;
  font-weight: 400;
  max-width: 40%;
  margin: 30px auto 0;
  line-height: 30px;
}

.about_subtitle {
  font-size: 20px;
  font-weight: 500;
  color: #44affc;
}

.about_maintitle {
  font-size: 38px;
  font-weight: 700;
  margin: 20px 0;
}

.about_maintitle span {
  color: #e6a123;
}

.about_sec_list li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
}

.about_sec_list li::before {
  content: "";
  background: url("../src/assets/images/list_icon.png") no-repeat;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
}

.feature_list {
  margin-top: 30px;
}

.feature_list_row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: default;
}

.feature_list_row > div {
  margin-left: 30px;
}

.feature_list_row h4 {
  font-size: 18px;
  font-weight: 500;
  color: #e6a123;
}

.feature_list_row p {
  margin-bottom: 0;
}

.laptop_section {
  position: relative;
  z-index: 1;
}

.laptop_section::after {
  content: "";
  background: url("../src/assets/images/about_arc_bg.png") no-repeat;
  width: 100%;
  height: 188px;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  background-size: cover;
  z-index: -1;
}

.top_features_section .feature_list_row h4 {
  font-size: 24px;
}

.contact_left_row svg {
  fill: #43adf7;
}

.contact_left_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact_left_row a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  color: #43adf7;
  margin-top: 10px;
}

.contact_left_row:first-child {
  border-bottom: 1px solid #1736a9;
  padding-bottom: 20px;
}

.contact_left_row:nth-child(2) {
  padding-top: 20px;
}

.cms_list_box {
  position: sticky;
  top: 120px;
}

ul.cms_content_list {
  padding-left: 20px;
}

.cms_content_list li {
  list-style: decimal;
  font-weight: 500;
  margin-bottom: 15px;
}

.cms_content_list li:last-child {
  margin-bottom: 0;
}

.cms_content_list li a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}

.cms_content_main h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
}

.cms_content_main h2 {
  font-size: 26px;
  font-weight: 700;
  color: #81cafc;
  margin-bottom: 10px;
}

.cms_content_main section {
  padding: 20px 0;
}

.cms_content_main ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
}

.cms_content_main ul li::before {
  content: "";
  background: #1736a9;
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 100px;
  top: 6px;
  left: 0;
}

.google_auth_qr img {
  width: 200px;
  margin-bottom: 15px;
}

/* Spot Trade Css Starts */
.trade_layout_wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

.trade_layout_main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-size: cover;
  margin-top: 100px;
  padding-bottom: 10px;
}

.trade_wrap {
  overflow: hidden;
  display: grid;
  grid-template-areas: "header" "chart" "marketSpecial" "depth" "buySell" "historyOrders" "openOrder";
  grid-template-rows: 150px 400px 400px 700px 920px 350px;
  grid-template-columns: 100%;
  grid-gap: 10px;
  padding: 10px;
}

.trade_pair {
  grid-area: header;
  background: linear-gradient(
    326deg,
    rgb(54 97 254 / 50%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgba(54, 97, 254, 0.5) 100%
  );
  border-radius: 15px;
  border: 1px solid #1736a9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.tradepair_details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.trading_pair_currency {
  display: flex;
  align-items: center;
  padding: 12px 0 10px 15px;
  border-bottom: 1px solid;
  border-color: #1736a9;
  flex: 40% 1;
  max-width: 40%;
}

.trading_pair_currency .tradepair_data {
  margin: 0;
  font-size: 20px;
}

.tradepair_data {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
}

.tradepair_lastprice {
  padding-bottom: 2px;
  border-bottom: 1px solid;
  position: relative;
  border-color: #1736a9;
  flex: 60% 1;
  max-width: 60%;
  padding-left: 15px;
  padding-right: 0;
}

.tradepair_label {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #81cafc;
}

.tradepair_volume {
  flex: 0 0 32%;
  max-width: 32%;
}

.tradepair_change,
.tradepair_high,
.tradepair_low {
  flex: 0 0 14%;
  padding-left: 15px;
}

.trade_chart {
  grid-area: chart;
  border-radius: 15px;
  border: 1px solid #1736a9;
}

.baseblock_wrapper {
  display: flex;
}

.baseblock_inner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 12px 15px;
}

.baseblock_inner_trade_chart {
  padding: 0;
}

.baseblock_content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tradingchart_wrap {
  height: 100%;
  padding: 10px;
}

.tradingview_chart {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.orderbook_wraper {
  grid-area: depth;
  background: linear-gradient(
    90deg,
    rgb(54 97 254 / 0%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgb(54 97 254 / 0%) 100%
  );
  border-radius: 15px;
  border: 1px solid #1736a9;
  overflow: hidden;
}

.orderbook_title {
  padding: 10px 15px;
  margin-bottom: 10px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.orderbook_title h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.orderbook_controller {
  display: flex;
  align-items: center;
  z-index: 1;
}

.orderbook_control_icon {
  height: 12px;
  width: 15px;
  cursor: pointer;
  position: relative;
  border-bottom: 2px solid #58bd7d;
  border-top: 2px solid #ff3838;
}

.orderbook_control_icon:before {
  background-color: #156ca7;
  width: 100%;
  height: 2px;
}

.orderbook_control_icon:after,
.orderbook_control_icon:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.orderbook_control_icon:after {
  width: 28px;
  height: 28px;
  right: -43%;
  opacity: 1;
  border-radius: 5px;
  z-index: -1;
  background-color: #010632;
}

.orderbook_control_icon_sell {
  border-bottom-color: #156ca7;
}

.orderbook_control_icon_buy {
  margin: 0 18px;
  border-top-color: #156ca7;
}

.orderbook_control_icon.active:after {
  background-color: #010632;
}

.orderbook_head {
  display: flex;
  padding: 0 9px;
}

.orderbook_tablehead_row {
  display: flex;
  flex-grow: 1;
  margin-bottom: 5px;
  padding-right: 10px;
}

.orderbook_tablehead_cell:first-child {
  padding-left: 10px;
}

.orderbook_tablehead_cell {
  flex: 1 1;
  font-size: 10px;
}

.orderbook_tablehead_cell:last-child,
.orderbook_tablehead_cell:nth-child(2) {
  padding-right: 5px;
}

.orderbook_tablehead_cell_right {
  text-align: right;
}

.orderbook_body {
  display: flex;
  padding: 0 9px;
  height: 100%;
  flex-direction: column;
}

.orderbook_body_wrap {
  display: flex;
  /* flex: 1 0 50%; */
}

.orderbook_body_wrap_hidden {
  display: none;
}

.orderbook_body_wrap_buy {
  padding-left: 6px;
  padding-bottom: 18px;
}

.basetable_wrap {
  width: 100%;
  overflow-x: auto;
}

.basetable_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.basetable_body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.basetable_orderbook .basetable_body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.basetable_sell .basetable_body {
  flex-direction: column-reverse;
}

.basetable_body_hover .basetable_row {
  cursor: pointer;
  transition: 0.2s all;
}

.basetable_row {
  display: flex;
  margin: 0 -4px;
  min-height: auto;
  max-height: 40px;
  padding: 0 10px;
}

.basetable_orderbook .basetable_row {
  position: relative;
  border-top: 1px solid transparent;
}

.basetable_cell {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 3px 4px;
  font-size: 12px;
}

.basetable_orderbook .basetable_cell {
  position: relative;
}

.orderbook_tablecell_txtend {
  justify-content: flex-end;
}

.basetable_sell.basetable_orderbook
  .basetable_row:not(.basetable_row_empty)::before {
  /* background-color: rgba(255, 56, 56, 0.1); */
}

.basetable_orderbook .basetable_row:not(.basetable_row_empty)::before {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgb(82 183 117 / 20%); */
  transform: translate(var(--fill-width), 2px);
  transition: transform 0.2s ease;
}

.orderbook_body_pricewrap {
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  align-items: center;
  background-color: #1736a9;
}

.orderbook_body_price {
  line-height: 20px;
  font-size: 18px;
  font-weight: 500;
}

.orderbook_body_price_down {
  color: #ff3838;
}

.orderbook_body_price_up {
  color: #58bd7d;
}

.orderbook_body_wrap .basetable_wrap {
  height: 450px;
}

.orderbook_body_wrap .basetable_wrap.basetable_wrap_fullheight {
  /* height: 896px; */
}

.baseblock_inner_nopadding {
  padding: 0;
}

.trade_buysell {
  grid-area: buySell;
  flex-direction: column;
  background: linear-gradient(
    326deg,
    rgb(54 97 254 / 50%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgba(54, 97, 254, 0.5) 100%
  );
  border-radius: 15px;
  border: 1px solid #1736a9;
}

.baseblock_inner_trade_buysell {
  padding-top: 10px;
}

.trade_buysell_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trade_buysell_body {
  display: flex;
  flex-grow: 1;
  margin-top: 10px;
  position: relative;
  flex-direction: column;
}

.buysellcontrol_wrapper {
  flex-grow: 1;
  border: none;
}

.buysellcontrol_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.buysellcontrol_head h4 {
  font-size: 16px;
  font-weight: 400;
}

.buysellcontrol_head h4 span {
  color: #1532a1;
}

.trade_buysell_head .deposit_btn {
  padding: 6px;
  min-width: 120px;
}

.buysellcontrol_wrapper .primary_inp_grp {
  display: flex;
  align-items: center;
}

.trade_buysell_control {
  display: flex;
  align-items: center;
}

.trade_buysell_control span {
  font-size: 13px;
  font-weight: 400;
  color: #43adf7;
}

.increament_btn_grp {
  display: flex;
  align-items: center;
  margin-left: 15px;
  border-left: 1px solid #1736a9;
  padding-left: 15px;
}

.increament_btn_grp button {
  width: 20px;
  height: 20px;
  color: #1532a1;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  border-radius: 3px;
}

.increament_btn_grp button + button {
  margin-left: 8px;
}

.increament_btn_grp button:hover {
  background: #81cafc;
}

ul.buypercent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 15px;
}

ul.buypercent li button {
  font-size: 15px;
  font-weight: 500;
  color: #81cafc;
  padding: 5px 12px;
  border-radius: 10px;
}

ul.buypercent li button:hover,
ul.buypercent li button.active {
  background: #042cb4;
}

.buysellcontrol_wrapper .sign_group {
  margin-bottom: 15px;
}

.buysellcontrol_wrapper .sign_group_input {
  padding: 5px 15px;
}

.buysellcontrol_wrapper .sign_input {
  padding: 2px 0 0 0;
}

.trade_sell_btn,
.trade_buy_btn {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  padding: 8px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 15px;
}

.trade_buy_btn {
  background: #58bd7d;
}

.trade_sell_btn {
  background: #ff3838;
}

.trade_buy_btn:hover {
  background: #0a7542;
}

.trade_sell_btn:hover {
  background: #db2424;
}

.buy_sell_tab {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.buy_sell_tab button {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
  color: #fff;
}

.buy_sell_tab button.buy_tab {
  background: #58bd7d;
}

.buy_sell_tab button.sell_tab {
  background: #ff3838;
}

.trade_market {
  grid-area: marketSpecial;
  background: linear-gradient(
    90deg,
    rgb(54 97 254 / 0%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgb(54 97 254 / 0%) 100%
  );
  border-radius: 15px;
  border: 1px solid #1736a9;
}

.baseblock_inner_trade_market,
.baseblock_inner_trade_history {
  padding-bottom: 8px;
}

.trade_market_search {
  /* display: flex;
  align-items: center; */
  width: 100%;
  height: 100%;
  flex: 0 1;
  margin-bottom: 15px;
}

.trade_market_search .primary_inp {
  height: 35px;
  padding: 5px 12px;
  font-size: 14px;
}

.trade_market_search .primary_inp_grp img {
  right: 12px;
  top: 10px;
}

.trade_market_specialwrapper {
  flex-grow: 1;
}

.basevirtualizedtable_wrapper {
  display: flex;
  flex-direction: column;
  height: 375px;
  margin-left: -18px;
  margin-right: -18px;
}

.trade_market_table_wrap {
  height: 100%;
}

.basevirtualizedtable_headrow {
  display: grid;
  grid-template-columns: 1.6fr 1fr 1fr;
  padding: 0 18px;
  font-size: 10px;
}

.basevirtualizedtable_headcell:first-child {
  padding-left: 5px;
}

.basevirtualizedtable_headcell {
  padding: 7px 0;
  font-size: 13px;
  color: #303db0;
}

.basevirtualizedtable_headcell:last-child {
  text-align: end;
}

.basevirtualizedtable_body {
  height: 100%;
  position: relative;
}

.basevirtualizedtable_bodyrow {
  display: grid;
  grid-template-columns: 1.6fr 1fr 1fr;
  padding: 0 18px;
  font-size: 10px;
  cursor: pointer;
}

.basevirtualizedtable_bodyrow:hover {
  background-color: rgb(129 202 252 / 10%);
}

.basevirtualizedtable_bodycell:first-child {
  padding-left: 5px;
  color: #43adf7;
}

.basevirtualizedtable_bodycell {
  display: flex;
  align-items: center;
  padding: 3px 0;
}

.basevirtualizedtable_bodycell:last-child {
  justify-content: flex-end;
  padding-right: 5px;
}

.basevirtualizedtable_bodycell i {
  margin-right: 3px;
  transition: 0.2s linear;
  color: #2b38a5;
}

.basevirtualizedtable_bodycell i:hover {
  transform: scale(1.3);
}

.basevirtualizedtable_bodycell i.active {
  color: #43adf7;
}

.trade_history {
  grid-area: historyOrders;
  background: linear-gradient(
    90deg,
    rgb(54 97 254 / 0%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgb(54 97 254 / 0%) 100%
  );
  border-radius: 15px;
  border: 1px solid #1736a9;
}

/* .trade_historyorder_wrapper {
  margin-left: -18px;
  margin-right: -18px;
} */
.basetable_thead_row {
  display: flex;
  margin: 0 0 5px;
  padding-right: 0;
}

.basetable_thead_th {
  font-size: 13px;
  color: #303db0;
  flex: 1 1;
  line-height: 20px;
  padding: 0;
}

.trade_history_tablecell_txtend {
  justify-content: flex-end;
}

.trade_historyorder_wrapper div[class^="basetable_cell"] {
  padding: 3px 0;
}

.trade_historyorder_wrapper
  .basetable_thead_row
  .basetable_thead_th:first-child {
  flex: 0 1 65px;
}

.trade_historyorder_wrapper .basetable_row .basetable_cell:first-child {
  flex: 0 0 65px;
  max-width: 65px;
  color: #43adf7;
}

.trade_historyorder_wrapper .basetable_row {
  padding: 0 5px;
}

.baseblock_inner .primary_tab {
  margin-bottom: 12px;
}

.ng-scrollbar-track {
  background-color: #01052f !important;
  border-radius: 10px !important;
}

.ng-scrollbar-thumb {
  background-color: #040f56 !important;
  border-radius: 10px !important;
}

.ng-scrollbar-wrapper[deactivated="false"][dir="ltr"]
  > scrollbar-y.scrollbar-control {
  right: 2px !important;
}

.trade_historyorder_wrapper .basetable_wrap {
  height: 270px;
}

.trade_layout_wrap .header__content {
  height: auto;
}

.required {
  color: #ff0f0f;
  padding-left: 5px;
  text-transform: capitalize;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.sign_btn.disabled {
  background-color: #45b1fc;
  cursor: not-allowed;
}

.sign_btn.disabled:hover {
  color: #000747;
}

.open_order {
  grid-area: openOrder;
  background: linear-gradient(
    90deg,
    rgb(54 97 254 / 0%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgb(54 97 254 / 0%) 100%
  );
  border-radius: 15px;
  border: 1px solid #1736a9;
}

.order_table_wrapper {
  padding: 10px;
}

/* New Css */
.ngx-mat-tel-input-container {
  position: relative;
}

.country-selector {
  opacity: 1 !important;
  bottom: 0 !important;
  left: 0 !important;
  color: #43adf7 !important;
  height: 36px !important;
  justify-content: flex-start !important;
  width: 100px !important;
}

.ngx-mat-tel-input-container input:not(.country-search) {
  color: #43adf7 !important;
  bottom: -6px;
  left: 12px;
}

.country-list-button {
  font-size: 14px !important;
}

.mat-mdc-menu-content {
  max-height: 340px;
}

.mat-mdc-menu-item.mdc-list-item {
  min-height: 40px !important;
  padding: 8px 12px !important;
}

.mdc-menu-surface {
  background-color: #000747 !important;
  color: #fff !important;
}

.mdc-list-item__primary-text {
  color: #fff !important;
}

input.country-search {
  border-bottom: 1px solid #1736a9 !important;
  background: #00063c;
  color: #fff;
}

.theme_btn {
  background: #042cb4;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  line-height: 12px;
}

div#tv_chart_container iframe {
  width: 100%;
}

.primary_tab {
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
  display: -webkit-box;
}

.primary_tab li.nav-item {
  white-space: nowrap;
}

.withdraw_btn_mw {
  min-width: unset;
  width: 100%;
}

.custom_tooltip .mdc-tooltip__surface.mdc-tooltip__surface-animation {
  background-color: #000747;
  color: #fff;
}

.user_info_dropdown a {
  color: #fff !important;
}

.user_info_dropdown a:hover {
  color: #44affc !important;
}

.user_info_dropdown ul.dropdown-menu {
  background: #45b1fc;
}

.user_info_dropdown ul.dropdown-menu li + li {
  margin-left: 0;
}

.user_info_dropdown ul.dropdown-menu li a:hover {
  background-color: #000747;
}

li.user_info_dropdown > a {
  display: flex;
  align-items: center;
  padding: 0;
}

.dashboard_theme {
  display: none !important;
}

.user_info_dropdown .sidebar__user-img span {
  transform: none;
  background: none;
  color: #fff !important;
}

.trade_history_table {
  height: 230px;
  overflow-y: auto;
}

/* Light Theme CSS */
body.light_theme {
  background: #ffffff;
  color: #2a2a2a;
}

.light_theme .page_header::before {
  background: url("../src/assets/images/banner_waves_light.png") no-repeat
    center;
  height: 1150px;
  top: -240px;
}

.light_theme .header {
  background-color: #ffffff;
}

.light_theme .home_navbar_middle li a {
  color: #2a2a2a !important;
}

.light_theme .home_navbar_middle li a,
.light_theme .home_menu_bar ul li a,
.light_theme .market_table thead th,
.light_theme .market_table tbody td,
.light_theme .market_table h5 span,
.light_theme .primary_btn a,
.light_theme .features_section h2 span,
.light_theme .ctaone_box div h5,
.light_theme .basevirtualizedtable_bodycell:first-child,
.light_theme .sign_input,
.light_theme .sign_input::placeholder,
.light_theme .primary_table thead th,
.light_theme .primary_table tbody td,
.light_theme .primary_table h5 span,
.light_theme .sign_group_checkbox input:not(:checked) + label,
.light_theme .sign_group_checkbox input:checked + label,
.light_theme .sidebar__nav-link,
.light_theme .cms_content_list li a,
.light_theme .dash_top_header ul.navbar-nav li a,
.light_theme .support_ticket_msg p,
.light_theme .deposit_crypto_inp > div h4 {
  color: #2a2a2a !important;
}

.light_theme .sidebar__nav-link svg {
  fill: #2a2a2a;
}

.light_theme .ctatwo_box {
  background: #152c7d;
}

.light_theme .primary_btn {
  border: 1px solid #bababa;
}

.light_theme .home_menu_bar ul li a.primary_btn:hover,
.light_theme .primary_btn:hover a {
  color: #fff !important;
}

.light_theme .market_section_panel,
.light_theme .features_div,
.light_theme .features_div:hover,
.light_theme .ctaone_box,
.light_theme .why_choose_div,
.light_theme .trade_pair,
.light_theme .trade_market,
.light_theme .trade_buysell,
.light_theme .orderbook_wraper,
.light_theme .trade_history,
.light_theme .dashbox,
.light_theme .open_order {
  border: 1px solid #e2e5ef;
  background: rgb(249, 250, 253);
  background: linear-gradient(
    -47deg,
    rgba(249, 250, 253, 1) 3%,
    rgba(237, 218, 218, 0) 50%,
    rgba(249, 250, 253, 1) 100%
  );
}

.light_theme .market_table tbody td,
.light_theme .market_table thead th,
.light_theme .primary_table thead th,
.light_theme .primary_table tbody td {
  border-bottom: 1px solid #e5d2d2;
}

.light_theme .market_table_btn_grps button,
.light_theme .token_search_list_cur_value {
  color: #848484;
}

.light_theme .market_table_btn_grps button.active,
.light_theme .market_table_btn_grps button:hover {
  background: transparent;
  border: 1px solid #bababa;
  color: #2a2a2a;
}

.light_theme .features_div a {
  color: #2555ff;
}

.light_theme .why_choose_section_img {
  background-repeat: no-repeat;
  background-position: center center;
  /* background: url("https://credexchange-frontend.pages.dev/assets/images/why_choose_img_light.png") no-repeat center; */
  background-size: 100% !important;
}

.light_theme .ctatwo_box .primary_btn {
  color: #45b1fc;
  border: 1px solid #45b1fc;
}

.light_theme .ctatwo_box .primary_btn a {
  color: #45b1fc !important;
}

.light_theme .mobile_app_section {
  background: #fff;
}

.light_theme .mobile_app_section ul li a h5 {
  color: #e6a123;
}

.light_theme .mobile_app_section ul li a h2,
.light_theme .cms_content_main h2 {
  color: #000747;
}

.light_theme .mobile_app_section ul li::before {
  background: #f2f2f2;
}

.light_theme footer {
  background: #fafafa;
}

.light_theme .footer_top p,
.light_theme ul.quick_link li a,
.light_theme .footer_bottom ul li a,
.light_theme .footer_bottom p,
.light_theme .sign_text {
  color: #8f8f8f;
}

.light_theme .subscribe_form input {
  border: 1px solid #d2d2d2 !important;
}

.light_theme .footer_bottom {
  border-top: 1px solid #d2d2d2;
}

.light_theme .trading_pair_currency {
  border-color: #e5d2d2;
}

.light_theme .trade_chart {
  border: 1px solid #e2e5ef;
}

.light_theme .basevirtualizedtable_headcell,
.light_theme .basetable_thead_th,
.light_theme .tradepair_label,
.light_theme .buysellcontrol_head h4 span,
.light_theme .sign_group_input label,
.light_theme .trade_buysell_control span,
.light_theme ul.buypercent li button,
.light_theme .orderbook_tablehead_cell,
.light_theme .primary_tab li button,
.light_theme .security_panel_content p,
.light_theme .support_ticket_accordian .accordion-button h2 span {
  color: #848484;
}

.light_theme
  .custom_tooltip
  .mdc-tooltip__surface.mdc-tooltip__surface-animation {
  background-color: #43adf7;
  color: #fff;
}

.light_theme .tradepair_data {
  color: #000747;
}

.light_theme .sign_group_input {
  border: 1px solid #d2d2d2;
  background: #fff;
}

.light_theme .increament_btn_grp button {
  color: #b2b2b2;
}

.light_theme .user_info_dropdown ul.dropdown-menu {
  background: #daeffe;
}

.light_theme .user_info_dropdown ul.dropdown-menu li a:hover {
  background-color: rgb(0 7 71 / 10%);
}

.light_theme .increament_btn_grp {
  border-left: 1px solid #e5d2d2;
}

.light_theme ul.buypercent li button:hover,
.light_theme ul.buypercent li button.active {
  background: #81cafc;
  color: #2a2a2a;
}

.light_theme .orderbook_control_icon:after {
  background-color: #f2f2f2;
}

.light_theme .orderbook_body_pricewrap {
  background: #e2e5ef;
}

.light_theme .primary_inp {
  background: #f5f4f4;
  border: 1px solid #d2d2d2;
}

.light_theme .primary_select {
  background: #f5f4f4 url("../src/assets/images/select_dropdown_light.png")
    no-repeat right 0.75rem center;
  border: 1px solid #d2d2d2;
  background-size: 16px 9px;
}

.light_theme .primary_tab li button.active,
.light_theme .primary_tab li button:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
  border: 1px solid #bababa;
}

.light_theme .ng-scrollbar-track {
  background-color: #f0efef !important;
}

.light_theme .ng-scrollbar-thumb {
  background-color: #d9cdcd !important;
}

.light_theme *::-webkit-scrollbar-thumb,
.light_theme::-webkit-scrollbar-thumb {
  background-color: #d9cdcd;
}

.light_theme .sign_group_checkbox input:not(:checked) + label:before,
.light_theme .sign_group_checkbox input:checked + label:before {
  background-color: #ffffff;
  border: 1px solid #45b1fc;
}

.light_theme .signin_btns button {
  background: #e7e7e7;
}

.light_theme .signin_btns button:hover,
.light_theme .signin_btns button.active {
  color: #fff;
  background: #43adf7;
}

.light_theme .country-selector {
  color: #2a2a2a !important;
}

.light_theme .ngx-mat-tel-input-container input:not(.country-search) {
  color: #2a2a2a !important;
}

.light_theme .mdc-menu-surface {
  background-color: #f9fafd !important;
  color: #2a2a2a !important;
}

.light_theme .mdc-list-item__primary-text {
  color: #2a2a2a !important;
}

.light_theme input.country-search {
  border-bottom: 1px solid #d2d2d2 !important;
  background: #f5f4f4;
  color: #2a2a2a;
}

.light_theme .sidebar {
  background: linear-gradient(320deg, #f9fafd 0%, #f9fafd 100%);
}

.light_theme .sidebar__user {
  background: rgb(0 2 20 / 8%);
}

.light_theme .sidebar__user-title a {
  color: #042cb4;
}

.light_theme .sidebar__user-title p,
.light_theme .wallet_balance_overview h3,
.light_theme .notification_row_content h2,
.light_theme .token_eventbox h2,
.light_theme .auth_content h2,
.light_theme .coin_web_info a {
  color: #000747;
}

.light_theme .coin_web_info svg {
  fill: #000747;
}

.light_theme .sidebar__nav-link.active,
.light_theme .sidebar__nav-link:hover {
  background: linear-gradient(
    -47deg,
    rgba(54, 97, 254, 1) 3%,
    rgba(0, 9, 74, 0) 50%,
    rgba(54, 97, 254, 1) 100%
  );
  color: #000747;
}

.light_theme .dash_chart_detail_top,
.light_theme .last_trans_list_row,
.light_theme .security_panel_row,
.light_theme .primary_modal .modal-header,
.light_theme .buycrypto_modal .modal-header,
.light_theme .create_ticket_div,
.light_theme .support_ticket_accordian .accordion-item,
.light_theme .support_ticket_accordian .accordion-button:not(.collapsed),
.light_theme .fiat_account_details h3,
.light_theme .launchpad_box {
  border-bottom: 1px solid #d2d2d2;
}

.light_theme .token_supply_details_left {
  border-right: 1px solid #d2d2d2;
}

.light_theme .dashbox_title_flex select {
  border: 1px solid #d2d2d2 !important;
  background: #f5f4f4 url("../src/assets/images/select_dropdown_light.png")
    no-repeat right 0.75rem center;
  background-size: 16px 9px;
  color: #2a2a2a;
}

.light_theme .airdrop_panel h2,
.light_theme .airdrop_panel h3,
.light_theme .sidebar__user-img {
  color: #fff;
}

.light_theme .wallet_balance_overview > div::before {
  background: #d2d2d2;
}

.light_theme .primary_modal .modal-content {
  background: #f9fafd;
  border: 1px solid #e2e5ef;
}

.light_theme .buycrypto_modal .modal-content {
  background: #fff;
  border: 1px solid #e2e5ef;
}

.light_theme .primary_modal .btn-close,
.light_theme .buycrypto_modal .btn-close {
  filter: unset;
}

.light_theme .bank_card h2,
.light_theme .bank_card_bottom h4,
.light_theme .countdown_wrapper .countdown_times p:nth-child(1) {
  color: #fff;
}

.light_theme select.sign_input {
  background: #fff url("../src/assets/images/select_dropdown_light.png")
    no-repeat right 0.75rem center;
  background-size: 16px 9px;
}

.light_theme select.sign_input option {
  background: #fff;
}

.light_theme .support_ticket_msg.support_ticket_usermsg {
  background: rgb(243 243 243);
}

.light_theme .support_ticket_msg.support_ticket_adminmsg {
  background: rgb(229 230 235);
}

.light_theme .custom-file-button label {
  color: #fff !important;
}

.light_theme .notification_row {
  border-top: 1px solid #d2d2d2;
}

.light_theme .primary_pagination ul.pagination .page-link {
  background: #f5f4f4;
  border-color: #d2d2d2;
  color: #2f2f2f;
}

.light_theme .launchpad_layout {
  background: #fff;
}

.light_theme ul.social_links li a {
  color: #777;
}

.light_theme ul.social_links li a:hover {
  color: #1b53b9;
}

.light_theme .laptop_section::after {
  filter: brightness(0) invert(1) contrast(0.8);
}

.light_theme .authbanner_wrapper {
  background: rgb(69 177 252 / 50%);
}

.light_theme .launchpad_page_header::before {
  background: url("../src/assets/images/launchpad_bg_light.png") no-repeat
    center;
}

.light_theme .launchpad_page_header_content h2,
.light_theme .launchpad_page_header_content h1 {
  color: #fff;
}

.light_theme .about_us_header::before {
  background: url("../src/assets/images/about_bg_light.png") no-repeat center;
  width: 100%;
  height: 764px;
}

.light_theme .support_ticket_accordian span.ticket_id,
.light_theme .support_ticket_accordian span.ticket_status,
.light_theme .sidebar__copyright {
  color: #2a2a2a;
}

.light_theme .support_ticket_accordian .accordion-button::after {
  filter: invert(1);
}

.light_theme .withdraw_btn a {
  color: #fff;
}

.light_theme .withdraw_btn:hover a {
  color: #43adf7;
}

.light_theme .tradepair_lastprice {
  border-color: #e5d2d2;
}

/* hide recaptcha badge  */
/* .grecaptcha-badge {
    visibility: hidden;
  } */

.wallet_addr .bgcolor {
  background: #00063c;
  display: flex;
  align-items: center;
  gap: 10px;
}

.light_theme .wallet_addr .bgcolor {
  background: #fff !important;
}

.wallet_addr .bgcolor img {
  position: unset;
}

.wallet_addr .primary_inp_grp {
  display: flex;
  align-items: center;
}

.trade_bal {
  color: #fff !important;
}

.light_theme .trade_bal {
  color: #2a2a2a !important;
}

/* Bots pair style */
.trade_card {
  background: #00094a;
  border-radius: 15px;
}

.search_inp_div {
  margin-top: 15px;
  padding: 0 10px;
  position: relative;
}

.strategy_filter .search_inp_div {
  margin-top: 0;
  padding: 0;
  width: 100%;
}

.settings_button i {
  color: #ffffff;
}

input.form-control.search_inp {
  background: #00063c;
  border: 1px solid #1736a9 !important;
  color: #ffffff;
  font-size: 14px;
  box-shadow: none !important;
  outline: 0 !important;
}

input.form-control.search_inp::placeholder {
  color: #3340b6;
}

.settings_button {
  background-color: #121a50;
  border-radius: 0.5rem;
  width: 35px;
  height: 35px;
}

.search_inp_div .bi-search {
  color: #1532a1;
  position: absolute;
  right: 10px;
  top: 5px;
}

.strategy_filter {
  align-items: center;
  display: flex;
  gap: 10px;
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

.pair_table_head {
  display: grid;
  grid-template-columns: 1.6fr 1fr 1fr;
  gap: 10px;
  margin-top: 15px;
  padding: 0 0px 10px;
}

.pair_table_head div {
  font-size: 14px;
  font-weight: 500;
  color: #43adf7;
}

.pair_table_head div:nth-child(2),
.pair_table_head div:nth-child(3) {
  text-align: right;
}

.pair_table_bodyrow_top img {
  width: 16px;
  margin-right: 5px;
}

.pair_table_bodyrow_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap;
}

.pair_table_bodyrow_top > div:first-child {
  display: flex;
  align-items: center;
}

.pair_table_bodyrow_top > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.direction_badge_wrap small {
  color: #ffffff;
  text-align: right;
  display: block;
}

.pair_table_bodyrow_top > div:first-child h3 {
  font-size: 14px;
  color: #3d9ee7;
  margin: 0;
  display: flex;
  align-items: center;
  line-height: 14px;
}

.pair_table_bodyrow_top > div:first-child h3 span {
  font-size: 14px;
  color: #3d9ee7;
  white-space: nowrap;
  margin-left: 3px;
}

.pair_table_bodyrow_top > div .bi {
  font-size: 15px;
  margin-left: 8px;
  color: #1d9bf0;
}

.pair_table_bodyrow_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 12px;
}

.pair_table_bodyrow_bottom label {
  font-size: 14px;
  color: #9d9fc3;
}

.pair_table_bodyrow_bottom_left > div label:nth-child(2),
.pair_table_bodyrow_bottom_right > div label:nth-child(2) {
  color: #536471;
}

.pair_table_bodyrow_bottom_right span small {
  font-size: 14px;
}

.pair_table_bodyrow {
  padding: 8px 8px 8px 8px;
  cursor: pointer;
  transition: 0.2s all;
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1.6fr 1fr 1fr;
  gap: 10px;
}

.pair_table_bodyrow.active,
.pair_table_bodyrow:hover {
  background: rgb(29 155 240 / 20%);
}

.pair_table_bodyrow_bottom_right span small {
  font-size: 14px;
}

.greenTxt {
  color: #2bbf89 !important;
}

.redText {
  color: #ff3636 !important;
}

.bottab {
  border: 0 !important;
  margin: 0;
}

.bottab .nav-link {
  border-radius: 5px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 15px;
  color: #81cafc;
  border: 0;
  min-width: 100px;
}

.bottab .nav-link.active {
  background: #81cafc;
  color: #000747;
}

.coin_details .card-header {
  padding-bottom: 0;
}

.coin_details .card-header h2 {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}

.trade_card .card-body {
  padding-top: 0;
}

.price-status {
  align-items: center;
  gap: 5px;
}

.filter_panel {
  grid-row-gap: 16px;
  display: grid;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
}

.filter_panel_row label {
  color: #536471;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
}

.filter_btn_grp {
  column-gap: 4px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
}

.filter_btn_grp button {
  align-items: center;
  background-color: #e6e6e6;
  border: 0;
  border-radius: 16px;
  color: #536471;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 14px;
  max-width: 100%;
  padding: 15px 30px;
}

.filter_btn_grp button.selected {
  background-color: #1d9bf0;
  color: #fff;
}

.filter_panel_btn {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 24px;
}

.filter_panel_btn .border_btn {
  background-color: #d8d8d8;
  border: 1px solid #d4d4d4;
  border-radius: 100px;
  color: #536471;
}

.continue {
  font-size: 15px;
  font-weight: 600;
  min-height: 48px;
  padding: 8px 20px;
  width: 100%;
  background-color: #1d9bf0;
  border: 0;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
}

.abv_flx {
  background-color: #000637 !important;
  border-radius: 16px;
  padding: 25px 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 10px;
}

.drpdwn_box button {
  background-color: #00063c !important;
  border: 1px solid #1736a9 !important;
  border-radius: 8px;
  box-shadow: none !important;
  color: #43adf7 !important;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  text-align: left;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.drpdwn_box button .exchange {
  color: #536471;
  display: block;
  font-size: 12px;
}

.exchange {
  margin-bottom: 12px;
  display: block;
  color: #3340b6;
}

.drpdwn_box .dropdown-menu {
  width: 100%;
  background-color: #00063c !important;
  border: 1px solid #1736a9 !important;
  padding-left: 12px;
  padding-right: 12px;
}

.drpdwn_box .dropdown-menu li a {
  color: #ffffff;
}

.drpdwn_box .dropdown-menu li a:hover {
  background-color: #042cb4;
}

.drpdwn_box .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 18px;
  border-width: 0.5em;
}

.drpdwn_box button img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
}

/* No Active Bots */
.noActiveBots .startButton {
  border-radius: 5px;
  padding: 6px 10px;
  background: #2eb85c !important;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  outline: none !important;
  border: 0 !important;
}

.noActiveBots {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 15px;
}

.noActiveBots img {
  margin-bottom: 12px;
}

/* bot History Styles */
.bot-history {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #00094a;
  border: 1px solid #1736a9;
  border-radius: 15px;
  padding: 15px;
}

.bot-history table {
  margin-bottom: 0;
}

.bot-history tr {
  border: 0;
}

.bot-history thead tr {
  border-bottom: 1px solid #1736a9;
}

.bot-history th {
  white-space: nowrap;
  background-color: transparent;
  color: #43adf7;
  font-weight: 500;
  font-size: 13px;
  border: 0;
}

.bot-history td {
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
}

.bot-history td:first-child {
  color: #3d9ee7;
}

.bot-history td:first-child img {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 5px;
}

.botType {
  border-radius: 5px;
  padding: 5px;
  background: #81cafc;
  font-weight: 600;
  font-size: 12px;
  color: #000747;
  margin-left: 5px;
}

.botAction {
  border-radius: 5px;
  padding: 5px;
  background: #58bd7d;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  margin-left: 5px;
}

.valueInPrice {
  color: #3340b6;
}

.bot-history-filter {
  margin-top: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bh-filter-left {
  display: inline-flex;
  align-items: center;
}

.bot-history-filter p {
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  color: #43adf7;
  margin-right: 15px;
}

.bh-filter-right .startButton {
  border-radius: 5px;
  padding: 6px 10px;
  background: #2eb85c !important;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  outline: none !important;
  border: 0 !important;
}

/* bot Chart Styles */
.botChart {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #00094a;
  border: 1px solid #1736a9;
  border-radius: 15px;
  height: 540px;
  margin-bottom: 12px;
}

/* bot page layout Styles */
.bot-box-midd {
  height: calc(100vh - 105px);
  position: sticky;
  top: 100px;
}

.bot-box-right {
  height: calc(100vh - 105px);
  position: sticky;
  top: 100px;
}

.pair_table_body {
  max-height: calc(100vh - 457px);
  overflow-y: auto;
}

/* bot order box Styles */

.bot-order-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: #00094a;
  z-index: 99;
  padding-top: 15px;
  padding-bottom: 12px;
}

.bot-order-head h4 {
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  margin: 0;
}

.dropSearch {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
}

.dropSearch .input-group .form-control {
  box-shadow: none !important;
  outline: none !important;
  background: #00063c;
  border: 1px solid #1736a9 !important;
  color: #ffffff;
  font-size: 14px;
  border-right: 0 !important;
}

.dropSearch .input-group .form-control::placeholder {
  color: #2e39aa;
}

.dropSearch .input-group-text {
  background: #00063c;
  border-color: #1736a9 !important;
  color: #1532a1;
}

.bot-order-select {
  margin-bottom: 20px;
}

.bot_preview_title {
  color: #3340b6;
  font-size: 14px;
  line-height: 16px;
}

.createbot_box .t_box {
  align-items: center;
  background-color: #58bd7d;
  border-radius: 10px;
  color: #fff;
  display: flex;
  font-weight: 600;
  font-size: 15px;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
}

.createbot_box .inputgrp_box input {
  height: auto;
  padding: 26px 11px 6px !important;
}

.createbot_box .inputgrp_box label {
  font-weight: 500;
  left: 10px;
  margin-bottom: 0;
  position: absolute;
  top: 5px;
  z-index: 10;
}
.createbot_box .inputgrp_box.center label {
  text-align: center;
  display: flex;
  width: 100%;
  margin-left: -10px;
  justify-content: center;
}
.flexNumberInput {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}
.flexNumberInput span,
.flexNumberInput input {
  border-radius: 0 !important;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}
.flexNumberInput span {
  cursor: pointer;
  background: #000f73 !important;
}
.flexNumberInput span i {
  cursor: pointer;
  font-size: 22px !important;
}
.createbot_box .grey {
  color: #3340b6;
  font-size: 12px;
}

.createbot_box .inputgrp_box {
  position: relative;
}

.inputgrp_box .input-group {
  border: 1px solid #1736a9;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12px;
}

.inputgrp_box .input-group .form-control {
  box-shadow: none !important;
  outline: none !important;
  background: #00063c;
  border: 1px solid #1736a9 !important;
  color: #ffffff;
  font-size: 14px;
  border-right: 0 !important;
  border: 0 !important;
}

.inputgrp_box .input-group .input-group-text {
  background: #00063c;
  border-color: #1736a9 !important;
  color: #40a5ee;
  border: 0 !important;
}

.inputgrp_box .input-group-text {
  background: #40a5ee;
  font-size: 12px !important;
  min-width: 42px;
  padding: 12px 10px;
}

.ngx-slider .ngx-slider-pointer {
  width: 20px !important;
  height: 20px !important;
  top: -8px !important;
  outline: none !important;
  box-shadow: none !important;
}

.ngx-slider .ngx-slider-pointer::after {
  top: 6px !important;
  left: 6px !important;
  background-color: #ffffff !important;
}

.ngx-slider .ngx-slider-bubble {
  color: #40a5ee !important;
}

.boxFlexText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.boxFlexText p.pricFont {
  font-weight: 600;
  font-size: 15px;
  color: #fff;
  margin: 0;
}

.boxFlexText p.pricFont span {
  font-size: 14px;
  color: #3340b6;
}

.bgcolor {
  -webkit-backdrop-filter: blur(3.5px);
  backdrop-filter: blur(3.5px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #00094a;
  bottom: 0;
  left: 0;
  padding: 15px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.bot-order {
  padding: 0 15px 15px;
  max-height: calc(100% - 78px);
  overflow-y: auto;
}
.accr_box {
  margin-top: 12px;
}
.accr_box .accordion-item {
  border-radius: 0 !important;
  background-color: transparent !important;
  border: 0 !important;
}

.accr_box .accordion-button {
  border-radius: 10px !important;
  padding: 12px 16px;
  background: #042cb4;
  border: 0 !important;
  font-weight: 600;
  font-size: 15px;
  color: #81cafc;
  box-shadow: none !important;
  outline: none !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

.accordion-button::after {
  background-image: none !important;
  display: inline-block;
  border-top: 0.45em solid;
  border-right: 0.45em solid transparent;
  border-bottom: 0;
  border-left: 0.45em solid transparent;
  height: 15px !important;
  margin-left: 15px !important;
  width: auto !important;
  height: auto !important;
}
.accordion-body .accordion-item {
  margin-top: 12px;
}
.accr_box .accordion-body .accordion-item .accordion-button {
  background: #002193;
  justify-content: space-between;
}
.botOrderRadiGroup {
  display: flex;
  margin-bottom: 12px;
}
.buttonStyleRadio {
  float: left;
  margin: 0 5px 0 0;
  min-width: 100px;
  min-height: 40px;
  position: relative;
}

.buttonStyleRadio label,
.buttonStyleRadio input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background: #00063c;
  border: 1px solid #1736a9;
  color: #40a5ee;
}

.buttonStyleRadio input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.buttonStyleRadio input[type="radio"]:checked + label {
  background: #81cafc;
  border: 1px solid #81cafc;
  color: #000747;
  border-radius: 4px;
}

.buttonStyleRadio label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
}
.accordion-body .createbot_box {
  background: #000c5b;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 15px;
}
.accordion-body .createbot_box:last-child {
  margin-bottom: 0;
}
.textColorWhite {
  color: #ffffff;
}
.createbot_box .boxFlexText .form-switch .form-check-input {
  width: 3em;
  height: 1.5em;
  box-shadow: none !important;
  outline: none !important;
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
.createbot_box .boxFlexText .form-switch .form-check-input:checked {
  background-color: #1d9bf0 !important;
  border-color: #1d9bf0 !important;
}
/* Media Queries */
@media (max-height: 650px) {
  .auth_right_img {
    display: none;
  }
}

@media (min-width: 360px) {
  .tradepair_lastprice {
    left: 0;
  }
}

@media screen and (min-width: 576px) {
  .main {
    padding: 30px 0 0;
  }
}

@media (min-width: 600px) {
  .orderbook_head {
    padding: 0;
  }

  .orderbook_tablehead_row {
    padding-right: 0;
  }

  .orderbook_tablehead_cell {
    font-size: 13px;
    color: #303db0;
  }

  .orderbook_body_wrap {
    flex: 1 1 auto;
  }

  .orderbook_body_wrap_buy {
    padding-left: 0;
  }

  .basetable_sell .basetable_body {
    flex-direction: column-reverse;
  }

  .basevirtualizedtable_headrow,
  .basevirtualizedtable_bodyrow {
    font-size: 12px;
  }

  .trading_pair_currency {
    flex: 18% 1;
    height: 100%;
    justify-content: center;
    padding: 0 15px;
    border-right: 1px solid;
    border-bottom: none;
    flex: 0 0 18%;
    max-width: 18%;
    border-color: #1736a9;
  }

  .trading_pair_currency .tradepair_data {
    font-size: 20px;
  }

  .tradepair_lastprice {
    flex: 18% 1;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    border-bottom: none;
    flex: 0 0 18%;
    max-width: 18%;
  }

  .tradepair_volume {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .tradepair_change,
  .tradepair_high,
  .tradepair_low {
    max-width: 14%;
  }
}

@media (min-width: 767px) {
  .trade_wrap {
    grid-template-areas: "header header" "chart chart" "buySell marketSpecial" "depth historyOrders" "openOrder openOrder";
    grid-template-rows: 72px 500px;
    grid-template-columns: 49.4% 49.4%;
  }

  .orderbook_body {
    flex-direction: column;
    padding: 0;
  }

  .trade_historyorder_wrapper .basetable_wrap {
    height: 520px;
  }
}

@media (min-width: 1200px) {
  .trade_wrap {
    grid-template-areas: "header header marketSpecial" "depth chart marketSpecial" "depth chart marketSpecial" "depth buySell historyOrders" "openOrder openOrder openOrder";
    grid-template-columns: 270px 1fr 300px;
    grid-template-rows: 72px 400px 120px 480px;
  }

  .buysellcontrol_wrapper_buy {
    padding-right: 10px;
    margin-bottom: 0;
  }

  .buysellcontrol_wrapper {
    width: auto;
  }

  .buysellcontrol_wrapper_sell {
    padding-left: 10px;
  }

  .trade_buysell_body {
    flex-direction: row;
  }

  .basevirtualizedtable_wrapper {
    height: 492px;
  }

  .trade_historyorder_wrapper .basetable_wrap {
    height: 380px;
  }

  .main {
    padding: 0 15px 30px 295px;
    margin-top: 120px;
  }

  .sidebar {
    transform: translateX(0);
  }

  .header__btn {
    display: none;
  }

  .header--fixed .header__content {
    transition: height 0.5s;
  }

  .header__content {
    height: 100px;
    overflow: visible;
  }

  .header--fixed {
    border-bottom: 1px solid transparent;
  }

  /* .header--active .header__content {
    height: 80px;
  } */
  .header--active {
    /* border-color: #1736a9; */
    box-shadow: 0 2px 5px #0000004d;
  }
}

@media (min-width: 1280px) {
  .trade_wrap {
    grid-template-columns: 260px 1fr 300px;
  }

  .trade_historyorder_wrapper .basetable_wrap {
    height: 380px;
  }
}

@media (min-width: 1440px) {
  .trade_wrap {
    grid-template-areas: "depth header marketSpecial" "depth chart marketSpecial" "depth chart marketSpecial" "depth buySell historyOrders" "openOrder openOrder openOrder";
    grid-template-rows: 72px 400px 50px 480px;
  }

  .basevirtualizedtable_wrapper {
    height: 410px;
  }

  .trade_historyorder_wrapper .basetable_wrap {
    height: 410px;
  }
}

@media (min-width: 1600px) {
  .trade_wrap {
    grid-template-rows: 72px 458px 0 480px;
    grid-template-columns: 384px 1fr 384px;
  }

  .tradepair_lastprice .tradepair_data {
    font-size: 14px;
  }

  .tradepair_label {
    margin-bottom: 3px;
  }

  .trade_historyorder_wrapper .basetable_wrap {
    height: 410px;
  }
}

@media screen and (max-width: 1399px) {
  .home_navbar_middle {
    margin-left: 30px;
  }

  .home_navbar_middle li + li {
    margin-left: 15px;
  }
}

@media screen and (max-width: 1366px) {
  .auth_right_img {
    width: 50%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .auth_content {
    margin-top: 30px;
  }

  .airdrop_panel h1 {
    max-width: 80%;
  }

  .support_ticket_accordian span.ticket_id,
  .support_ticket_accordian span.ticket_status {
    font-size: 14px;
  }

  .support_ticket_accordian .accordion-button h2 {
    font-size: 16px;
  }
}

@media screen and (max-width: 1279px) {
  .airdrop_panel h1 {
    max-width: 90%;
  }

  .dashbox.verify_box {
    padding: 30px 12px;
  }

  .wallet_balance_overview > div::before {
    left: 135%;
  }

  .dash_chart_detail_top {
    display: block;
  }

  .dash_chart_detail_top > div:nth-child(2) {
    margin-top: 15px;
  }

  .chart_list li label {
    font-size: 16px;
  }
}

@media screen and (max-width: 1199px) {
  .home_menu_bar {
    display: block;
    padding: 0;
    background: linear-gradient(
      320deg,
      rgba(0, 5, 53, 1) 0%,
      rgba(4, 46, 187, 1) 100%
    );
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 101;
    width: 280px;
    transform: translateX(-100%);
    transition: 0.5s ease;
    border-right: 1px solid rgba(47, 128, 237, 0.1);
  }

  .dash_top_header .navbar-nav {
    display: none;
  }

  .mobile_none {
    display: none;
  }

  .desktop_none {
    display: block !important;
  }

  .home_menu_bar ul li + li {
    margin-left: 0;
  }

  .home_menu_bar ul li a {
    text-align: center;
  }

  /* .theme_btn {
    margin: 15px auto 0;
  } */
  .home_menu_bar ul li a.primary_btn {
    margin-top: 15px;
  }

  .page_header h5,
  .page_header h1 {
    max-width: 100%;
  }

  .features_panel {
    justify-content: space-between;
  }

  .ctaone_box {
    padding: 60px 20px;
  }

  .ctaone_box div h2 {
    font-size: 36px;
  }

  .ctatwo_box > div {
    max-width: 50%;
  }

  .mobile_app_section ul li::before {
    width: 70%;
  }

  .authbanner_wrapper {
    width: 500px;
  }

  .auth_form_wrapper {
    width: calc(100% - 500px);
  }

  .wallet_balance_overview > div::before {
    left: 160%;
  }

  /* .buysellcontrol_wrapper_sell {
    display: none;
  } */
  .launchpad_box.launchpad_detailbox {
    display: block;
  }

  .launchpad_detailbox img {
    margin-bottom: 15px;
  }

  .launchpad_detailbox_content .launchpad_box_content p {
    max-width: 100%;
  }

  .token_supply_details {
    margin-left: 0;
  }

  li.user_info_dropdown > a {
    justify-content: center;
  }

  .user_info_dropdown ul.dropdown-menu {
    position: absolute;
    width: 100%;
    left: 10px;
  }

  .user_info_dropdown ul li a {
    color: #fff !important;
    text-align: left !important;
  }

  .buysellcontrol_wrapper_sell {
    margin-top: 30px;
  }

  .light_theme .home_menu_bar {
    background: linear-gradient(320deg, #f9fafd 0%, #f9fafd 100%);
  }

  .light_theme .header__btn span {
    background-color: #2f80ed;
  }

  .sidebar__nav-item {
    margin-bottom: 10px;
  }

  .dashboard_theme {
    position: absolute;
    top: 30px;
    right: 70px;
    display: block !important;
  }

  li.nav-item.user_info_dropdown {
    margin-bottom: 10px;
    background: rgb(0 2 20 / 25%);
    padding: 20px 30px;
  }

  .light_theme .nav-item.user_info_dropdown {
    background: rgb(0 2 20 / 8%);
  }
}

@media screen and (max-width: 991px) {
  .features_panel {
    flex-wrap: wrap;
    justify-content: center;
  }

  .features_div {
    margin: 0 30px 30px 0;
  }

  .market_table_head {
    flex-wrap: wrap;
    justify-content: center;
  }

  .market_table_head h2 {
    font-size: 42px;
    margin-bottom: 15px;
  }

  .market_table tbody td,
  .market_table thead th {
    padding: 15px 30px;
  }

  .ctaone_box {
    flex-wrap: wrap;
  }

  .ctaone_box div {
    width: 50%;
  }

  .ctaone_box div:nth-child(1),
  .ctaone_box div:nth-child(2) {
    margin-bottom: 30px;
  }

  .ctatwo_box > div {
    max-width: 100%;
    text-align: center;
  }

  .mobile_app_section h2 {
    font-size: 36px;
  }

  .features_section h2 {
    font-size: 42px;
  }

  .mobile_app_section ul li::before {
    width: 90%;
  }

  .footer_top [class*="col-"]:last-child {
    margin-top: 20px;
  }

  .authbanner_wrapper {
    display: none;
  }

  .auth_form_wrapper {
    width: 500px;
    height: 100%;
    padding: 100px 30px;
    min-height: 100vh;
    display: block;
    margin: 0 auto;
  }

  .primary_table thead th,
  .primary_table tbody td {
    padding: 10px 20px;
  }

  .dashbox.verify_box {
    padding: 30px 30px;
  }

  .support_ticket_accordian .accordion-button {
    display: block;
  }

  .support_ticket_accordian .accordion-button h2 {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .support_ticket_msg {
    width: 80%;
  }

  .support_ticket_accordian .accordion-button::after {
    top: 50%;
  }

  .primary_tab {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
    display: -webkit-box;
  }

  .primary_tab li.nav-item {
    white-space: nowrap;
  }

  .wallet_balance_overview > div::before {
    display: none;
  }

  .withdraw_bal_div {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .footer_bottom {
    display: block;
    text-align: center;
  }

  .footer_bottom ul {
    justify-content: center;
    margin-top: 15px;
  }

  .launchpad_page_header_content h1 {
    font-size: 100px;
  }

  .launchpad_page_header_content {
    height: 100%;
    padding: 500px 0 80px;
  }

  .token_supply_details {
    flex-wrap: wrap;
  }

  .token_supply_details_left,
  .token_supply_details_right {
    width: 100%;
  }

  .token_supply_details_left {
    border-bottom: 1px solid #0e1874;
    border-right: 0;
    padding-bottom: 10px;
  }

  .token_supply_details_right {
    padding-top: 30px;
  }

  .token_supply_details_left > div span:nth-child(2) {
    width: auto;
  }

  .top_features_section .feature_list_row {
    display: block;
  }

  .top_features_section .feature_list_row > div {
    margin-left: 0;
    margin-top: 15px;
  }

  .about_us_page_header_content h1 {
    max-width: 100%;
  }

  .about_us_page_header_content h5 {
    max-width: 100%;
  }

  .about_us_page_header_content {
    height: 100%;
    padding: 200px 0;
  }

  .about_subtitle {
    margin-top: 30px;
  }

  .contact_left_box {
    margin-top: 50px;
  }

  .chart_list li {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .slick-slider .slick-slide img {
    margin: 0 auto;
  }

  .features_div p {
    min-height: unset;
  }

  .why_choose_div {
    min-height: auto;
  }

  .banner_content {
    margin-top: 0;
    text-align: center;
  }

  .banner_content .get_start_btn {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .mobile_app_section {
    text-align: center;
  }

  .mobile_app_section ul li::before {
    display: none;
  }

  .footer_top [class*="col-"]:nth-child(2),
  .footer_top [class*="col-"]:nth-child(3) {
    margin-top: 30px;
  }

  .dash_chart_detail_top div:nth-child(2) a + a {
    margin-left: 10px;
  }

  .deposit_btn,
  .withdraw_btn {
    min-width: 130px;
  }

  .sign_content {
    width: 100%;
  }

  .security_panel_row {
    flex-wrap: wrap;
  }

  .security_panel_content p {
    max-width: 100%;
    margin-bottom: 15px;
  }

  .security_panel_row > div {
    display: block;
  }

  .security_panel_row svg {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .support_ticket_msg {
    width: 100%;
  }

  .notification_row {
    display: block;
  }

  p.notify_date {
    margin-left: 35px;
    margin-top: 10px;
  }

  .notification_row > div {
    width: 100%;
  }

  .wallet_balance_overview {
    flex-wrap: wrap;
    justify-content: center;
  }

  .wallet_balance_overview > div {
    width: 50%;
  }

  .basevirtualizedtable_wrapper {
    height: 265px;
  }

  .orderbook_body_wrap .basetable_wrap {
    height: 280px;
  }

  .orderbook_body_wrap .basetable_wrap.basetable_wrap_fullheight {
    height: 582px;
  }

  .launchpad_box {
    display: block;
  }

  .launchpad_box img {
    margin-right: 0;
    margin-bottom: 15px;
    width: 75px;
    height: 75px;
  }

  .launchpad_page_header_content h1 {
    font-size: 80px;
    line-height: 150px;
  }

  .launchpad_page_header_content h2 {
    font-size: 42px;
  }

  .launchpad_detailbox_content {
    display: block;
  }

  .launchpad_box_content .coin_web_info {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .top_features_section .feature_list_row,
  .top_features_section .about_maintitle {
    text-align: center;
  }

  .about_maintitle {
    font-size: 32px;
  }

  .about_us_page_header_content h1 {
    font-size: 38px;
  }

  .cms_list_box {
    display: none;
  }

  .dash_chart_panel {
    flex-wrap: wrap;
  }

  .dash_chart_detail {
    width: 100%;
  }

  .ctatwo_box::before {
    background-size: cover;
  }

  .webcam-wrapper video {
    height: 15rem !important;
    width: 18rem !important;
  }

  .captureImage {
    height: 15rem !important;
    width: 18rem !important;
  }
}

@media screen and (max-width: 575px) {
  .file_box label {
    text-align: center;
  }

  .security_panel_row .file_box {
    margin-top: 0;
  }

  .ctaone_box div {
    width: 100%;
    margin-bottom: 20px;
  }

  .page_header h1 {
    font-size: 36px;
  }

  .features_section h2,
  .market_table_head h2,
  .ctatwo_box h2,
  .mobile_app_section h2 {
    font-size: 32px;
  }

  .market_section_panel {
    padding: 40px 20px;
  }

  .market_table_btn_grps button + button {
    margin-left: 4px;
  }

  .market_table_btn_grps button {
    padding: 7px 12px;
  }

  .why_choose_section h2 span {
    font-size: 42px;
    line-height: 48px;
  }

  .auth_form_wrapper {
    width: 100%;
  }

  .chart_list li {
    width: 100%;
  }

  .deposit_btn,
  .withdraw_btn {
    display: block;
  }

  .dash_chart_detail_top div:nth-child(2) span + span {
    margin-left: 0;
    margin-top: 15px;
  }

  .dash_chart_detail {
    width: 100%;
  }

  .dash_chart_panel {
    flex-wrap: wrap;
  }

  .dashbox_title_flex {
    display: block;
  }

  .dashbox_title_flex select {
    width: 100%;
  }

  .dashbox_title_flex .dashbox_title {
    margin-bottom: 15px;
  }

  .support_btn_grp button + button {
    margin-left: 0;
    margin-top: 15px;
  }

  .support_ticket_accordian span.ticket_id,
  .support_ticket_accordian span.ticket_status {
    display: block;
  }

  .support_ticket_accordian span.ticket_status {
    margin-left: 0;
    margin-top: 12px;
  }

  .support_ticket_msg {
    display: block;
  }

  .support_ticket_msg img {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .dashbox_title_flex a + a {
    margin-top: 15px;
    margin-left: 0;
  }

  .wallet_balance_overview > div {
    width: 100%;
    padding: 10px 0;
  }

  .tradepair_change,
  .tradepair_high,
  .tradepair_low {
    flex: 0 0 14%;
    padding: 0 15px;
  }

  .tradepair_volume {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }

  .tradepair_volume .tradepair_label {
    text-align: center;
  }

  .fiat_account_details > div {
    display: block;
  }

  .fiat_account_details > div div {
    width: 100%;
  }

  .fiat_wallet_btns a + a {
    margin-left: 0;
    margin-top: 15px;
  }

  .launchpad_page_header_content h2 {
    font-size: 32px;
    margin-bottom: 0;
  }

  .launchpad_page_header_content h1 {
    font-size: 42px;
    line-height: 60px;
  }

  .launchpad_box_content > div {
    display: block;
  }

  /* .launchpad_box_content > div span:nth-child(2) {
    display: block;
    width: 100%;
  } */
  .token_supply_details_left > div {
    display: block;
  }

  .token_supply_details_left > div span:nth-child(2) {
    display: block;
  }

  .countdown_wrapper {
    width: 100%;
  }

  .countdown_wrapper .countdown_times span {
    font-size: 18px;
  }

  .countdown_wrapper .countdown_times p:nth-child(2) {
    font-size: 12px;
  }

  .countdown_wrapper .countdown_times p:nth-child(1) {
    font-size: 14px;
    width: 45px;
    height: 45px;
    border-radius: 15px;
  }

  .contact_left_row {
    align-items: flex-start;
    flex-direction: column-reverse;
  }

  .contact_left_row svg {
    margin-bottom: 15px;
  }

  .contact_left_row a {
    margin-top: 0;
  }

  .cms_content_main h1 {
    font-size: 26px;
  }

  .cms_content_main h2 {
    font-size: 22px;
  }

  .edit_profile_user_info .sidebar__user-img span {
    font-size: 24px;
  }

  .edit_profile_user_info .sidebar__user-img {
    width: 50px;
    height: 50px;
  }

  .edit_profile_head {
    flex-direction: column;
    align-items: flex-start;
  }

  .edit_profile_head button {
    margin-top: 15px !important;
  }

  .custom_file_upload label {
    max-width: 85%;
  }
}

@media screen and (max-width: 350px) {
  .bank_card_bottom,
  .bank_card_top {
    display: block;
    text-align: center;
  }

  .bank_card_top > div {
    justify-content: center;
    margin-top: 10px;
  }

  .bank_card_bottom > div {
    margin-top: 10px;
  }

  .bank_card_bottom h5 {
    margin-bottom: 5px;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.vrf_btn {
  margin: 15px auto 0;
  width: 100%;
  border-radius: 15px;
  background-color: #45b1fc;
  border: 1px solid #45b1fc;
  font-size: 20px;
  color: #000747;
  text-transform: capitalize;
  font-weight: 700;
  padding: 8px;
}
/* ---------------------gaayathri---------------------- */
.detail_model .bot-history {
  box-shadow: none;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 15px;
  padding: 0px;
}
.detail_model .bot-history td:first-child {
  color: #fff;
}
.pagination {
  justify-content: end;
  margin: 10px 0;
}
.pagination .page-item .page-link {
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 12px;
}
.pagination .page-item .page-link:focus {
  box-shadow: none;
}
.mat-mdc-form-field-infix {
  width: 0 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
  padding-left: 0;
  border-radius: 6px;
  /* border: 1px solid #81cafc; */
}
.mdc-text-field__input {
  /* width: 20px !important; */
  background-color: #81cafc !important;
}
.mat-mdc-form-field-flex {
  /* background-color: #81cafc !important; */
  padding: 0 !important;
  /* border: 1px solid #81cafc !important; */
  border-radius: 6px !important;
  width: 38px !important;
  height: 40px !important;
  padding: 0px !important;
}
.mat-mdc-icon-button {
  padding: 0 !important;
  background-color: #81cafc !important;
  border: 1px solid #81cafc !important;
}
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  padding-left: 0 !important;
}
.mat-mdc-icon-button {
  width: auto !important;
  height: auto !important;
  padding: 6px !important;
  border-radius: 0 !important;
}
.mat-mdc-icon-button:hover {
  background-color: #81cafc !important;
}
.mat-mdc-icon-button:active {
  background-color: #81cafc !important;
}
.mat-mdc-icon-button.active {
  background-color: #81cafc !important;
}
.mat-mdc-icon-button:focus {
  background-color: #81cafc !important;
}
.mat-calendar-previous-button,
.mat-calendar-next-button {
  width: 40px !important;
  height: 40px !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.mat-calendar-previous-button:hover,
.mat-calendar-next-button:hover {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.mat-calendar-previous-button:active,
.mat-calendar-next-button:active {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.mat-calendar-body-selected {
  background-color: #81cafc;
}
.mat-calendar-body-selected:focus {
  background-color: #81cafc;
}
.mat-calendar-body-cell:hover .mat-calendar-body-cell-content {
  background-color: #81cafc !important;
  color: #fff !important;
}
.mat-calendar-previous-button:focus,
.mat-calendar-next-button:focus {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.mat-calendar-previous-button.active,
.mat-calendar-next-button.active {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.mat-datepicker-content {
  background-color: #000747;
  border: 1px solid #1736a9;
}
.mat-calendar-table-header th {
  color: #81cafc;
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #81cafc;
}
.mat-calendar-table-header,
.mat-calendar-body-label {
  color: #81cafc;
}
.mat-mdc-button {
  color: #81cafc !important;
}
.mat-calendar-arrow {
  fill: #81cafc;
}

.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: #81cafc !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover
  .mdc-line-ripple::before {
  border-bottom-color: transparent !important;
}
.mdc-line-ripple::before {
  border-bottom-color: transparent !important;
}
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: 6px !important;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple:hover {
  background-color: #81cafc !important;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple:active {
  background-color: #81cafc !important;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple:focus {
  background-color: #81cafc !important;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple.active {
  background-color: #81cafc !important;
}
/* ---------------------gaayathri-light-theme--------------------- */
.light_theme .subscription-main {
  background: url(./assets/images/subscription_bg_light.png) no-repeat !important;
  background-size: inherit !important;
  background-position: top !important;
}
.light_theme .mat-datepicker-content {
  background: #f9fafd !important;
  border: 1px solid #e2e5ef !important;
}
.light_theme .mat-calendar-table-header th {
  color: #2a2a2a;
}
.light_theme .mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: #2a2a2a;
}
.light_theme .mat-calendar-table-header,
.light_theme .mat-calendar-body-label {
  color: #2a2a2a;
}
.light_theme .mat-mdc-button {
  color: #2a2a2a !important;
}
.light_theme .mat-calendar-arrow {
  fill: #2a2a2a;
}

.light_theme .mat-datepicker-content .mat-calendar-next-button,
.light_theme .mat-datepicker-content .mat-calendar-previous-button {
  color: #2a2a2a !important;
}
.mat-calendar-body-selected {
  background-color: #81cafc;
}
.light_theme .mat-calendar-body-cell:hover .mat-calendar-body-cell-content {
  background-color: #81cafc !important;
}
.light_theme .light_theme .subscription-box-head {
  background: #fff !important;
}

.light_theme .subscription-head h6 {
  color: #2a2a2a;
}

.light_theme .subscription-head h4 {
  color: #2a2a2a;
}

.light_theme .subscription-tab {
  border: 1px solid #45b1fc !important;
  color: #45b1fc;
  background-color: transparent;
}

.light_theme .subscription-tab.nav-tabs .nav-link.active {
  background: #45b1fc !important;
  border: 1px solid transparent;
  color: #000747 !important;
}

.light_theme .subscription-tab.nav-tabs .nav-link:active {
  background: #45b1fc !important;
  border: 1px solid transparent;
  color: #000747 !important;
}

.light_theme .subscription-tab.nav-tabs .nav-link:hover {
  background: #45b1fc !important;
  border: 1px solid transparent;
  color: #000747 !important;
}

.light_theme .subscription-box {
  background: linear-gradient(
    -47deg,
    rgba(249, 250, 253, 1) 3%,
    rgba(237, 218, 218, 0) 50%,
    rgba(249, 250, 253, 1) 100%
  ) !important;
  border: 1px solid #e2e5ef !important;
}

.light_theme .subscription-title h5 {
  color: #2a2a2a !important;
}

.light_theme .subscription-title p {
  color: #2a2a2a !important;
}

.light_theme .subscription-title h6 {
  color: #2a2a2a !important;
}

.light_theme .subscription-card p {
  color: #2a2a2a !important;
  background: #e2e5ef6e !important;
}

.light_theme .subscription-list {
  color: #2a2a2a !important;
}

.light_theme .subscription-box-highlight {
  border: 3px solid #e19c01 !important;
}

.light_theme .myexchange-title {
  color: #2a2a2a !important;
}

.light_theme .myexchange-tab.nav-tabs .nav-link {
  border: 1px solid #030d650d !important;
}

.light_theme .dark_img {
  display: none;
}

.light_theme .light_img {
  display: block !important;
}

.light_theme .myexchange-tab.nav-tabs .nav-item.show .nav-link,
.light_theme .myexchange-tab.nav-tabs .nav-link.active {
  border: 1px solid #030d650d !important;
  background: #030d650d !important;
}

.light_theme .myexchange-tab .nav-item h6 {
  color: #2a2a2a !important;
}

.light_theme .blue-box {
  border: 1px solid #030d650d !important;
  background: #030d650d !important;
}

.light_theme .blue-box .head {
  color: #2a2a2a !important;
}

.light_theme .blue-box li {
  color: #2a2a2a !important;
}

.light_theme .blue-box .input-group {
  border: 1px solid #1736a94f !important;
  background: #00063c33 !important;
}

.light_theme .blue-box input {
  color: #2a2a2a !important;
}

.light_theme .blue-box input::placeholder {
  color: #2a2a2a !important;
}

.light_theme .blue-box .form-check-input:checked[type="checkbox"] {
  background-image: url(./assets/images/checked_light.svg) !important;
}
.light_theme .botChart {
  background: #f9fafd !important;
  border: 1px solid #e2e5ef !important;
}
.light_theme .bot-history {
  background: #f9fafd !important;
  border: 1px solid #e2e5ef !important;
}
.light_theme .bot-box-midd {
  background: #f9fafd !important;
  border: 1px solid #e2e5ef !important;
}
.light_theme .bot-box-right {
  background: #f9fafd !important;
  border: 1px solid #e2e5ef !important;
}
.light_theme .bottab .nav-link {
  color: #2a2a2a;
}
.light_theme .bottab .nav-link.active {
  background: #81cafc;
  color: #000747;
}
.light_theme .bot-history-filter p {
  color: #2a2a2a;
}
.light_theme .bot-history th {
  color: #2a2a2a;
}
.light_theme .bot-history td {
  color: #2a2a2a;
}
.light_theme .bot-history td:first-child {
  color: #3d9ee7;
}
.light_theme .bot-history thead tr {
  border-bottom: 1px solid #1736a929;
}
.light_theme .bot-order-head {
  background: #f9fafd;
  color: #3d9ee7;
}
.light_theme .bot-order-head h4 {
  color: #000637;
}
.light_theme .bot_preview_title {
  color: #2a2a2a;
}
.light_theme .bot_preview_box {
  background-color: #00063714 !important;
}
.light_theme .bgcolor {
  background: #f9fafd;
}
.light_theme .abv_flx {
  background-color: #00063714 !important;
}
.light_theme .exchange {
  color: #2a2a2a;
}
.light_theme .drpdwn_box button {
  background-color: #f5f4f4 !important;
  border: 1px solid #d2d2d2 !important;
  color: #2a2a2a !important;
}
.light_theme .drpdwn_box .dropdown-menu {
  background-color: #f5f4f4 !important;
  border: 1px solid #d2d2d2 !important;
  color: #2a2a2a !important;
}
.light_theme .drpdwn_box .dropdown-menu li a {
  color: #2a2a2a !important;
}
.light_theme .drpdwn_box .dropdown-menu li a:hover {
  background-color: #042cb405;
}
.light_theme .trade_card {
  background-color: transparent !important;
  border: 1px solid transparent;
}
.light_theme .coin_details .card-header h2 {
  color: #000637;
}
.light_theme .coin_details .card-header {
  background: transparent;
  border-bottom: 1px solid #00000014;
}
.light_theme input.form-control.search_inp {
  background-color: #f5f4f4 !important;
  border: 1px solid #d2d2d2 !important;
  color: #2a2a2a !important;
}
.light_theme input.form-control.search_inp::placeholder {
  color: #2a2a2a !important;
}
.light_theme .search_inp_div .bi-search {
  color: #2a2a2a !important;
}
.light_theme .settings_button {
  background: #121a5036;
}
.light_theme .settings_button i {
  color: #2a2a2a;
}
.light_theme .pair_table_head div {
  color: #2a2a2a !important;
}
.light_theme .direction_badge_wrap small {
  color: #2a2a2a !important;
}
.light_theme .pair_table_bodyrow.active,
.light_theme .pair_table_bodyrow:hover {
  background: #1d9bf00d;
}
.light_theme .dropSearch .input-group .form-control {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: #2a2a2a !important;
}
.light_theme .dropSearch .input-group .form-control::placeholder {
  color: #2a2a2a !important;
}
.light_theme .dropSearch .input-group-text {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.light_theme .dropSearch .input-group {
  background-color: #f5f4f4 !important;
  border: 1px solid #d2d2d2 !important;
}
.light_theme .dropSearch .input-group-text {
  color: #2a2a2a !important;
}
.light_theme .inputgrp_box .input-group .form-control {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: #2a2a2a !important;
}
.light_theme .inputgrp_box .input-group {
  border: 1px solid #1736a926;
}
.light_theme .inputgrp_box .input-group .input-group-text {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.light_theme .ngx-slider .ngx-slider-bubble {
  color: #2a2a2a !important;
}
.light_theme .accr_box .accordion-button {
  background: #042cb41a;
  color: #2a2a2a !important;
}
.light_theme .accr_box .accordion-body .accordion-item .accordion-button {
  background: #042cb414;
  color: #2a2a2a !important;
}
.light_theme .accordion-body .createbot_box {
  background: #000c5b0f;
}
.light_theme .textColorWhite {
  color: #2a2a2a !important;
}
.light_theme .dcabot-details-tab-head {
  background: #f9fafd !important;
}
.light_theme .detail-box {
  background: #00063c0d !important;
  border: 1px solid #1736a91f !important;
}
.light_theme .detail-box p {
  color: #2a2a2a !important;
}
.light_theme .detail-box h6 {
  color: #3d9ee7 !important;
}
.light_theme .detail_model .bot-history {
  border: 1px solid transparent !important;
}
.light_theme .collapsible-content {
  background: #f9fafd !important;
  border: 1px solid #1736a940 !important;
}
.light_theme .bgcolor .bot_preview_box h5 {
  color: #000637 !important;
}
.light_theme .bgcolor .bot_preview_box p {
  color: #2a2a2a !important;
}
.light_theme .bgcolor .bot_preview_box:hover {
  background-color: #01062d47 !important;
}
.light_theme .bot_preview_box h6 {
  color: #2a2a2a !important;
}
.light_theme .bot_preview_box p {
  color: #2a2a2a !important;
}
.light_theme .bot-stop-detail {
  background-color: #0109471c !important;
}
.light_theme .create-bot .progress {
  background-color: #006bbb2b !important;
}
